import PropTypes from 'prop-types';

import Feed from 'core/components/Feed';
import H2 from 'core/components/H2';

import CardSameZodiac from 'site/cards/CardSameZodiac';

import Divider from 'site/components/Divider';

import styles from './index.styl';

const PLURAL_SIGNS = {
  'aries': 'Овны',
  'taurus': 'Тельцы',
  'gemini': 'Близнецы',
  'cancer': 'Раки',
  'leo': 'Львы',
  'virgo': 'Девы',
  'libra': 'Весы',
  'scorpio': 'Скорпионы',
  'sagittarius': 'Стрельцы',
  'capricorn': 'Козероги',
  'aquarius': 'Водолеи',
  'pisces': 'Рыбы',
};

export default function SameZodiacStars(props) {
  const {
    period,
    zodiac,
    content,
  } = props;

  if (!content) return null;

  return (
    <div>
      <Divider />
      <H2>Знаменитости {PLURAL_SIGNS[zodiac]}</H2>
      <div className={styles.titlePeriod}>{period}</div>
      <div className={styles.itemsWrapper}>
        <Feed
          card={CardSameZodiac}
          content={content}
          interitemSpacing={20}
          columns={2}
          grid
        />
      </div>
    </div>
  );
}

SameZodiacStars.propTypes = {
  content: PropTypes.array,
  period: PropTypes.string,
  zodiac: PropTypes.string,
};
