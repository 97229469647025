import TopicGallery from 'site/components/TopicGallery';
import Image from 'core/components/Image';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';

import { coverAsGallery } from 'site/utils';

const relationships = resolveRelationships(
  ['image', 'content'],
  {},
  {
    image: { versions: {} },
    content: { widgets: [] },
  },
);

export default function CustomCover({ content }) {
  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
      caption,
    },
    content: {
      widgets,
    },
  } = relationships(content);

  const {
    headline,
    is_premium: isPremium,
  } = content.attributes;
  const altAndTitle = caption || headline;

  /**
   * Если в новости первый виджет - мини-галерея, ее ставить вместо обложки.
   * https://jira.rambler-co.ru/browse/UCMS-2243
   */

  if (coverAsGallery(content)) {
    return <TopicGallery photoGallery={widgets[0]} />;
  }

  if (!cover) return null;

  return (
    <div style={{
      maxWidth: cover.width,
      margin: '0 auto',
      ...isPremium && { display: 'flex', justifyContent: 'center' },
    }}
    >
      <Image
        src={cover}
        previewSrc={previewCover}
        maxWidth={isPremium ? 1280 : 820}
        maxHeight={600}
        placement='contain'
        useSchema
        alt={altAndTitle}
        title={altAndTitle}
      />
    </div>
  );
}

CustomCover.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
};
