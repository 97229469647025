import PropTypes from 'prop-types';

import cx from 'classnames';

import withTheme from 'core/components/theme';

import styles from './index.styl';

function SectionTitle(props) {
  const {
    className,
    children,
    theme,
    level,
    align,
    htmlTag: HtmlTag,
    ...otherProps
  } = props;
  return (

    <HtmlTag
      {...otherProps}
      className={cx(styles.title, styles['_level' + level], className)}
    >
      <style jsx>{`
        .${styles.title}
          font-family ${theme.fonts.nuance}
          color ${theme.colors.primary}
          text-align ${align}
      `}</style>
      {children}
    </HtmlTag>
  );
}

SectionTitle.defaultProps = {
  level: 1,
  htmlTag: 'div',
  align: 'left',
};

SectionTitle.propTypes = {
  className: PropTypes.string,
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
  htmlTag: PropTypes.string,
  theme: PropTypes.object,
  align: PropTypes.oneOf(['left', 'center', 'right']),
};

export default withTheme(SectionTitle);
