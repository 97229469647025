import oneLine from 'common-tags/lib/oneLine';


const fonts = {
  display: 'Spectral, Spectral-fallback, Times New Roman, serif',
  get text() {
    return this.display;
  },
  nuance: 'Lato, Lato-fallback, Arial, sans-serif',
  faces: [
    {
      fontFamily: 'Spectral',
      src: oneLine`
        local('Spectral Regular'), local('Spectral-Regular'),
        url('${require('./spectral/spectral-v6-cyrillic_latin-regular.woff2')}') format('woff2'),
        url('${require('./spectral/spectral-v6-cyrillic_latin-regular.woff')}') format('woff')
      `,
    },
    {
      fontFamily: 'Spectral',
      fontWeight: 500,
      src: oneLine`
        local('Spectral Medium'), local('Spectral-Medium'),
        url('${require('./spectral/spectral-v6-cyrillic_latin-500.woff2')}') format('woff2'),
        url('${require('./spectral/spectral-v6-cyrillic_latin-500.woff')}') format('woff')
      `,
    },
    {
      fontFamily: 'Spectral',
      fontWeight: 300,
      src: oneLine`
        local('Spectral Light'), local('Spectral-Light'),
        url('${require('./spectral/spectral-v6-cyrillic_latin-300.woff2')}') format('woff2'),
        url('${require('./spectral/spectral-v6-cyrillic_latin-300.woff')}') format('woff')
      `,
    },
    {
      fontFamily: 'Spectral',
      fontStyle: 'italic',
      src: oneLine`
        local('Spectral Italic'), local('Spectral-Italic')
        url('${require('./spectral/spectral-v6-cyrillic_latin-italic.woff2')}') format('woff2'),
        url('${require('./spectral/spectral-v6-cyrillic_latin-italic.woff')}') format('woff')
      `,
    },
    {
      fontFamily: 'Spectral',
      fontWeight: 500,
      fontStyle: 'italic',
      src: oneLine`
        local('Spectral Medium Italic'), local('Spectral-MediumItalic'),
        url('${require('./spectral/spectral-v6-cyrillic_latin-500italic.woff2')}') format('woff2'),
        url('${require('./spectral/spectral-v6-cyrillic_latin-500italic.woff')}') format('woff')
      `,
    },
    {
      fontFamily: 'Spectral',
      fontWeight: 200,
      fontStyle: 'italic',
      src: oneLine`
        local('Spectral ExtraLight Italic'), local('Spectral-ExtraLightItalic'),
        url('${require('./spectral/spectral-v6-cyrillic_latin-200italic.woff2')}') format('woff2'),
        url('${require('./spectral/spectral-v6-cyrillic_latin-200italic.woff')}') format('woff')
      `,
    },
    {
      fontFamily: 'Lato',
      src: oneLine`
        local('Lato Regular'), local('Lato-Regular'),
        url('${require('./lato/lato-regular.woff2')}') format('woff2'),
        url('${require('./lato/lato-regular.woff')}') format('woff')
      `,
    },
    {
      fontFamily: 'Lato',
      fontWeight: 300,
      src: oneLine`
        local('Lato Thin'), local('Lato-Thin'),
        url('${require('./lato/lato-thin.woff2')}') format('woff2'),
        url('${require('./lato/lato-thin.woff')}') format('woff')
      `,
    },
    {
      fontFamily: 'Lato-fallback',
      sizeAdjust: '97.38%',
      ascentOverride: '98%',
      src: 'local("Arial")',
    },
    {
      fontFamily: 'Spectral-fallback',
      sizeAdjust: '110%',
      ascentOverride: '115%',
      src: 'local("Times New Roman")',
    },
  ],
};
export default fonts;
