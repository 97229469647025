import React, { useState, useEffect, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';

import queryString from 'core/libs/query-string';
import { withRouter } from 'core/libs/router';

import Feed from 'core/components/Feed';
import Button from 'core/components/Button';

import bindProps from 'core/components/bindProps';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import { VerticalIndent } from 'site/components/Indents';

import { Card3Type1 } from 'site/cards/Card3';
import Card5 from 'site/cards/Card5';

import { STARS_LIMIT } from 'site/constants';


const include = filterRequiredParams([Card5], 'include');
const fields = filterRequiredParams([Card5], 'fields');

/**
 * Загружает из апи данные топиков `getTopics()` по slug тега
 *
 * @author Aleksey Dibrivniy <a.dibrivniy@rambler-co.ru>
 */

const Card5Custom = bindProps({ imageMaxWidth: 394 })(Card5);

function TopicsTaggedWithStar(props, { bebopApi }) {
  const {
    isDesktop,
    topicsDataAndMeta: {
      topics,
      meta: { filtered_count: count },
    },
    excludedId,
    location,
    history,
  } = props;

  const [topicsState, setTopicsState] = useState(topics);
  const [topicsPage, setTopicsPage] = useState(5);

  const loadMoreHandler = useCallback(() => {
    const newTopicsPage = topicsPage + 1;
    setTopicsPage(newTopicsPage);

    const {
      match: { params: { level_2: level2 } },
    } = props;

    return bebopApi.getTopics({
      include,
      fields,
      tag: level2,
      limit: STARS_LIMIT,
      offset: (newTopicsPage - 1) * STARS_LIMIT,
      excluded_ids: excludedId,
      ...props,
    }).then(data => {
      setTopicsState(prevTopicsState => ([...prevTopicsState, ...denormalizeData(data)]));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicsPage]);

  const {
    pathname,
  } = location;

  /**
   * При округлении обязательно делаем фолбек на `1` в случае если при расчете
   * будет получен `0` из-за отсутсвия данных иначе мы получем бесконечный
   * редирект из-за срабатывания условия `!checkPageUrlParam(location, pageCount)`.
   */

  const pageCount = Math.ceil(count / STARS_LIMIT) || 1;

  useEffect(() => {
    const { page } = queryString.parse(location.search);

    if (!!page) {
      history.replace(pathname);
    }
  }, [pathname, history, location.search]);

  return (
    <Fragment>
      <VerticalIndent />
      <Feed
        content={topicsState}
        interitemSpacing={30}
        card={isDesktop ? Card5Custom : Card3Type1}
        columns={2}
        grid
      />
      <VerticalIndent />
      {topicsState?.length < count - 1 && (
        <Button
          size={isDesktop ? 'large' : 'medium'}
          onClick={loadMoreHandler}
          minWidth={'100%'}
          disabled={topicsPage >= pageCount}
        >
          Показать еще
        </Button>
      )}
    </Fragment>
  );
}

TopicsTaggedWithStar.propTypes = {
  isDesktop: PropTypes.bool,

  topicsDataAndMeta: PropTypes.shape({
    topics: PropTypes.arrayOf(modelPropTypes(topicAttributes).isRequired),
    meta: PropTypes.shape({
      filtered_count: PropTypes.number,
    }),
  }),

  excludedId: PropTypes.string,

  /** @ignore */
  match: PropTypes.object.isRequired,

  /** @ignore */
  location: PropTypes.object.isRequired,

  /** @ignore */
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

TopicsTaggedWithStar.contextTypes = {
  bebopApi: PropTypes.object,
};

export default withRouter(withBreakpoint(TopicsTaggedWithStar));
