import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import withPageHocs from 'core/components/withPageHocs';

import ContentLayout from 'site/components/ContentLayout';
import RubricPageByType from 'site/components/RubricPageByType';

import rubricPageFetcher from 'site/fetchers/rubricByType';

import Skeleton from '../rubric/Skeleton';
import tags from './tags';


function PhotoPage({ data }) {
  return (
    <ContentLayout
      tags={tags}
      title='Фотогалереи'
      metaTitle='Фотогалереи на WMJ'
      metaDescription='Фотогалереи: звезды, события, ковровая дорожка, модные показы на WMJ'
    >
      <RubricPageByType data={data} />
    </ContentLayout>
  );
}

PhotoPage.propTypes = {
  data: PropTypes.object,
};

const dataProvider = resolve({
  data: rubricPageFetcher({ topic_type: 'gallery' }),
});

export default withPageHocs(dataProvider, Skeleton)(PhotoPage);
