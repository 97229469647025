import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import GlobalStyles from 'site/components/Ads/GlobalStyles';
import './index.styl';

const NativeStyles = ({ theme }) => (
  <Fragment>
    <style global jsx>{`
      .listing-spec-search-native
        .ad_native_title
          color ${theme.colors.primary}
          font 18px/1.2 ${theme.fonts.display}
        .ad_label__text
          color ${theme.colors.primary}
          font 14px/1.2 ${theme.fonts.display}
    `}</style>
  </Fragment>
);

NativeStyles.propTypes = {
  theme: PropTypes.object,
};

export default function ContentSpecNative(Component) {
  return withTheme(props => (
    <div className='adf-wrapper listing-spec-search-native'>
      <GlobalStyles {...props} />
      <NativeStyles {...props} />
      <Component {...props} />
    </div>
  ));
}
