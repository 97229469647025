import color from 'core/libs/color';

const colors = {
  layout: '#fff',
  content: '#f6f6f6',
  card: '#fff',
  active1: '#a68b5c',
  active2: '#dfc991',
  accent: '#efe5d4',
  error: '#ff3e56',
  primary: '#000',
  footer: '#fff',
  input: '#fff',
  darkBlue: '#232E3A',
  divider: color('#979797').alpha(0.5).string(),
  get leadDivider() {
    return this.divider;
  },
  get hint() {
    return color(this.primary).alpha(0.5).string();
  },
  get placeholder() {
    return color(this.primary).alpha(0.3).string();
  },
  get placeholder2() {
    return color(this.primary).alpha(0.1).string();
  },
  get theme() {
    return this.active1;
  },
};

export default {
  colors,
};
