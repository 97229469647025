import React from 'react';
import PropTypes from 'prop-types';

import queryString from 'core/libs/query-string';
import { withRouter } from 'core/libs/router';
import { Helmet } from 'core/libs/helmet';

import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';
import Page from 'core/components/Page';
import H1 from 'core/components/H1';
import Boroda from 'site/components/Boroda';
import Tags from 'core/components/Tags';
import Tag from 'core/components/Tag';

import { VerticalIndent } from 'site/components/Indents';

import InnerSideColumn from 'site/components/InnerSideColumn';
import Divider from 'site/components/Divider';

function ContentLayout(props) {
  const {
    location: {
      search,
      pathname,
    },
    title,
    tags,
    metaTitle,
    metaDescription,
    titleTemplate,
    children,
  } = props;

  const {
    rubric: rubricQuery,
    tag: tagQuery,
    page: pageQuery,
  } = queryString.parse(search);

  const tagButtons = [(
    <Tag
      key={'all'}
      type={!rubricQuery && !tagQuery ? 'secondary' : 'ghost'}
      inactive={!rubricQuery && !tagQuery}
      state={!rubricQuery && !tagQuery ? 'idle' : 'auto'}
      link={pathname}
    >
      Все
    </Tag>
  )];

  tags.forEach(item => {
    const isActive = rubricQuery === item.slug || tagQuery === item.slug;

    tagButtons.push(
      <Tag
        key={item.slug}
        type={isActive ? 'secondary' : 'ghost'}
        state={isActive ? 'idle' : 'auto'}
        inactive={isActive}
        link={`${pathname}?${item.type}=${item.slug}`}
      >
        {item.title}
      </Tag>
    );
  });

  return (
    <Page
      title={`${metaTitle || title}${pageQuery > 1 ? ` - страница ${pageQuery}` : ''}`}
      description={metaDescription}
    >
      <Helmet titleTemplate={titleTemplate} />
      <PageIndent>
        <ColumnLayout rightColumn={<InnerSideColumn />}>
          <H1 dataQa='page-title'>{title}</H1>
          {tagButtons && (
            <div>
              <Divider />
              <Tags verticalSpacing={10} horizontalSpacing={0}>
                {tagButtons}
              </Tags>
            </div>
          )}
          <VerticalIndent />
          {children}
        </ColumnLayout>
        <VerticalIndent />
        <Boroda />
        <VerticalIndent />
      </PageIndent>
    </Page>
  );
}

ContentLayout.propTypes = {
  title: PropTypes.string,
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  titleTemplate: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      slug: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
};

export default withRouter(ContentLayout);
