import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import cardHOC from 'core/components/card';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import styles from './index.styl';

import relationshipsPropTypes, { imagePropTypes } from 'core/utils/prop-types/relationships';

import resolveRelationships from 'core/utils/relationships';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import VideoIcon from 'site/icons/Video';

import mapTypes from './types';

const linkSecondary = ({ theme }) => theme.controls.link.secondary;

const requiredPayloadImports = [
  'image',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'topic_type',
  'list_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});


function Card6(props) {
  const {
    content,
    type,
    theme,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    topic_type: topicType,
  } = content.attributes;

  const {
    imageType = 'original',
    placement,
    withIcon,
  } = mapTypes[type] || {};

  const {
    image: {
      versions: {
        [imageType]: cover,
      },
    },
  } = relationships(content);

  const isVideo = topicType === 'video';

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .link
          :global([id*="$"])
            transition fill ${theme.animations.hover}

          :global([id*="!"])
            transition stroke ${theme.animations.hover}

          &:hover
            :global([id*="$"])
              fill ${linkSecondary({ theme }).hover.color}

            :global([id*="!"])
              stroke ${linkSecondary({ theme }).hover.color}
      `}</style>
    </scope>
  );

  return (
    <div className={styles.card} data-qa='card6'>
      <style jsx>{`
        .headline
          font-family ${theme.fonts.text}
      `}</style>

      <Link
        to={link}
        type='secondary'
        className={cx(
          styles['card-link'],
          scoped.wrapClassNames('link'),
        )}
        data-qa='card6-link'
      >
        <div className={styles['card-image']} data-qa='card6-image'>
          <Image
            src={cover}
            width={195}
            height={587}
            placement={placement}
            position='50% 0'
          />
          {isVideo && withIcon && (
            <VideoIcon
              width='40px'
              height='40px'
              className={styles['card-icon']}
            />
          )}
        </div>
        <div
          className={cx(
            styles['card-content_block'],
            styles['card-headline'],
          )}
          data-qa='card6-headline'
        >
          {headline || listHeadline}
        </div>
      </Link>
      <scoped.styles />
    </div>
  );
}

Card6.propTypes = {
  content: relationshipsPropTypes({
    image: imagePropTypes(),
  }),

  /**
   * Модификатор, позволяющий конфигурировать разновидности карточки.
   */
  type: PropTypes.oneOf([0]),
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

Card6.defaultProps = {
  type: 0,
};

const Card = cardHOC(Card6);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;
