import modelPropTypes, {
  photoGalleryAttributes,
} from 'core/utils/prop-types/model';

import GalleryWithAds from 'site/components/GalleryWithAds';
import RelatedGalleries from 'site/components/RelatedGalleries';


function TopicGallery(props) {
  const {
    photoGallery,
  } = props;

  return photoGallery ? (
    <GalleryWithAds
      hashNavigation
      photoGallery={photoGallery}
      additionalSlide={RelatedGalleries}
      adsToReload={[
        {
          name: 'Billboard',
          count: 4,
        },
        {
          name: '240x400',
          count: 4,
        },
      ]}
    />
  ) : null;
}

TopicGallery.propTypes = {
  photoGallery: modelPropTypes(photoGalleryAttributes).isRequired,
};

export default TopicGallery;
