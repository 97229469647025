import { Fragment } from 'react';
import cx from 'classnames';

import { Desktop } from 'core/components/breakpoint';
import { Indent } from 'core/components/Wrappers';

import SocializatorSkeleton from 'core/components/Socializator/Skeleton';

import styles from './index.styl';

export default function WishList() {
  return (
    <Fragment>
      <div className={cx('lb-skeleton', styles.headline)} />
      <Indent top={20} />
      <Desktop>
        <Indent bottom={40}>
          <SocializatorSkeleton />
        </Indent>
      </Desktop>
      <div className={cx('lb-skeleton', styles.content)} />
    </Fragment>
  );
}
