import PropTypes from 'prop-types';

import bindProps from 'core/components/bindProps';
import replaceCardWithAd from 'core/components/Ad/hoc/replaceCardWithAd';
import Feed from 'core/components/Feed';
import { withBreakpoint } from 'core/components/breakpoint';

import { Native2Card4 } from 'site/components/Ads/desktop';
import { Card3Type3, Card3Type4 } from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';
import SectionTitle from 'site/components/SectionTitle';


const HeaderComponent = bindProps({ align: 'center' })(SectionTitle);

const NewsFeed = ({ content, isDesktop }) => (
  <Feed
    content={content}
    title='Новости'
    headerComponent={HeaderComponent}
    interitemSpacing={isDesktop ? 30 : 15}
    heroCard={isDesktop ? Card4 : Card3Type3}
    card={isDesktop ? Card4 : Card3Type4}
    columns={3}
    grid
    cardsReplacement={{
      4: replaceCardWithAd(Native2Card4, isDesktop),
    }}
    dataQa='news-feed'
  />
);

NewsFeed.propTypes = {
  content: PropTypes.array,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(NewsFeed);
