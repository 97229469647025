import { topicsFetcher } from 'core/fetchers/topics';

import Card8 from 'site/cards/Card8';


export default (customParams) => (componentProps) => {
  const props = { ...componentProps, ...customParams };
  return topicsFetcher({
    limit: 12,
    topic_type: 'horoscope',
    sort: 'horoscope',
    plugin: JSON.stringify(
      {
        horoscope: {
          kind: 'general',
          period: 'today',
        },
      }
    ),
    ...props,
    card: props.card || Card8,
    include: props.include || 'all',
  })(props);
};
