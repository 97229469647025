import { Mobile, Desktop } from 'core/components/breakpoint';
import AdWrapper from 'core/components/Ad/AdWrapper';

import {
  Sponsored as DesktopSponsored,
} from 'site/components/Ads/desktop';

import {
  Sponsored as MobileSponsored,
} from 'site/components/Ads/mobile';

/**
 * Компонент ответственнен за отрисовку рекламных блоков "Sponsored" в начале и в конце топика
 */
export default function Sponsored() {
  return (
    <AdWrapper bottom={20}>
      <Desktop>
        <DesktopSponsored />
      </Desktop>
      <Mobile>
        <MobileSponsored />
      </Mobile>
    </AdWrapper>
  );
}
