import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import GlobalStyles from 'site/components/Ads/GlobalStyles';

import styles from './index.styl';

const NativeStyles = ({ theme }) => {
  const {
    block: {
      background,
      padding,
    },
    title: {
      font,
      color,
      textTransform,
      letterSpacing,
      textAlign,
    },
  } = theme.controls.vikontReadMore;

  return (
    <Fragment>
      <style global jsx>{`
        .card4-type4
          .ad_native_wrapper
            padding ${padding}
            background ${background}

          .ad_label__text
            font ${font}
            color ${color}
            text-transform ${textTransform}
            letter-spacing ${letterSpacing}
            text-align ${textAlign}

          .ad_native_title
            font 16px/1.2 ${theme.fonts.text}

          .ad_native_desc
            font italic 14px/1.2 ${theme.fonts.text}

          .ad_native_img
            border 3px solid ${theme.colors.layout}
      `}</style>
    </Fragment>
  );
};

NativeStyles.propTypes = {
  theme: PropTypes.object,
  breakpoint: breakpointPropTypes(),
};

export default function Card4Type4(Component) {
  return withTheme(withBreakpoint(props => (
    <div className={cx(styles['card4-type4'], 'adf-wrapper card4-type4')}>
      <GlobalStyles {...props} />
      <NativeStyles {...props} />
      <Component {...props} />
    </div>
  )));
}
