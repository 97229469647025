import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import GlobalStyles from 'site/components/Ads/GlobalStyles';
import styles from './index.styl';

const NativeStyles = ({ theme, blockId }) => (
  <Fragment>
    <style global jsx>{`
      .adf-wrapper-card5.block-${blockId}

        .ad_native_title
          font-family ${theme.fonts.text}

        .ad_label__text
          font 16px/20px ${theme.fonts.nuance}
          color ${theme.colors.active2}
    `}</style>
  </Fragment>
);

NativeStyles.propTypes = {
  blockId: PropTypes.string,
  theme: PropTypes.object,
};

export default function card5Styles(Component) {
  return withTheme(props => (
    <div className={cx(styles['adf-wrapper-cars5'], `adf-wrapper adf-wrapper-card5 block-${props.blockId}`)}>
      <GlobalStyles {...props} />
      <NativeStyles {...props} />

      <Component {...props} />
    </div>
  ));
}
