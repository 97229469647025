import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Mjolnir from 'core/components/Mjolnir';
import { Mobile, Desktop } from 'core/components/breakpoint';

import bindProps from 'core/components/bindProps';

import RelatedGalleriesContext from 'site/components/RelatedGalleriesContext';

import Card9 from 'site/cards/Card9';

import styles from './index.styl';


const HeroCard = bindProps({ type: 1, imageMaxWidth: 778 })(Card9);
const GalleriesCard = bindProps({ imageMaxWidth: 374 })(Card9);

export default function RelatedGalleries() {
  const { relatedGalleries: content } = useContext(RelatedGalleriesContext);

  return (
    <div className={styles.container}>
      <Mobile>
        <Card9 content={content[0]} imageMaxWidth={700} />
      </Mobile>
      <Desktop>
        <Mjolnir
          interitemSpacing={30}
          content={content}
          heroCard={HeroCard}
          card={GalleriesCard}
        />
      </Desktop>
    </div>
  );
}

RelatedGalleries.propTypes = {
  content: PropTypes.array,
};
