import deepmerge from 'core/libs/deepmerge';

import {
  BLOCK_HORIZONTAL_MOBILE,
  BLOCK_VERTICAL_MOBILE,
} from 'site/components/Indents';

import { Card3Type5 } from 'site/cards/Card3';

import { scooterMenuLink } from './base';

import fonts from './fonts';


export default function baseMobileTheme(atoms) {
  const texts = {
    title1: {
      color: atoms.colors.primary,
    },
    title2: {
      color: atoms.colors.primary,
    },
    title3: {
      color: atoms.colors.primary,
      font: `500 18px/24px ${fonts.display}`,
    },
    title4: {
      color: atoms.colors.primary,
    },
    title5: {
      color: atoms.colors.primary,
    },
    title6: {
      color: atoms.colors.primary,
    },

    lead: {
      font: `300 17px/24px ${fonts.display}`,
      marginTop: '20px',
    },

    caption1: {
      font: `12px/14px ${fonts.nuance}`,
    },
  };

  const vikontReadMore = {
    block: {
      padding: '18px 15px',
    },
    title: {
      letterSpacing: '0.08em',
      marginBottom: '16px',
      textAlign: 'center',
    },
    drum: {
      itemWidth: 215,
    },
  };

  const scooter = {
    logo: {
      width: 77,
      height: 24,
    },
    padding: `0 ${BLOCK_HORIZONTAL_MOBILE}px`,
    menuLink: scooterMenuLink,
    social: {
      size: 34,
      iconSize: 16,
      margin: 0,
    },
  };

  const shapka = {
    logo: {
      width: 77,
      height: 24,
    },
    padding: `0 ${BLOCK_HORIZONTAL_MOBILE}px`,
  };

  const expert = {
    name: {
      idle: {
        font: `600 12px/21px ${fonts.nuance}`,
      },
      hover: {
        font: `600 12px/21px ${fonts.nuance}`,
      },
    },
    jobTitle: {
      font: `400 12px/17px ${fonts.nuance}`,
    },
    label: {
      font: `400 8px/1 ${fonts.nuance}`,
    },
  };

  const topicFooter = {
    commentButton: {
      size: 'medium',
      topSpacing: BLOCK_VERTICAL_MOBILE,
    },
  };

  const topicTopline = {
    font: `12px/1 ${fonts.nuance}`,
  };

  const commentsPreview = {
    headline: {
      font: `500 24px/1 ${fonts.text}`,
    },
    name: {
      font: `500 14px/18px ${fonts.text}`,
    },
  };

  const vikontImage = {
    maxWidth: 795,
  };

  const errorPage = {
    imgWidth: 130,
    title: {
      font: `250 80px/92px ${fonts.nuance}`,
    },
    subtitle: {
      font: `20px/26px ${fonts.text}`,
    },
    card: Card3Type5,
  };

  const storyCard = {
    headline: {
      font: `600 11px/14px ${fonts.display}`,
    },
  };

  const storyWidgetsBaseCard = {
    headline: {
      font: `500 22px/28px ${fonts.display}`,
    },
  };

  const pages = {
    error: errorPage,
    notFound: errorPage,
    sentryFallback: {
      ...errorPage,
      title: {
        font: `300 40px/46px ${fonts.nuance}`,
      },
      card: null,
    },
  };

  const layout = {
    indents: {
      top: BLOCK_VERTICAL_MOBILE,
      bottom: BLOCK_VERTICAL_MOBILE,
      left: BLOCK_HORIZONTAL_MOBILE,
      right: BLOCK_HORIZONTAL_MOBILE,
    },
  };

  const topicHeaderTexts = {
    title: {
      font: `500 26px/32px ${fonts.display}`,
    },
    subtitle: {
      font: `200 italic 26px/32px ${fonts.display}`,
    },
    divider: {
      margin: '15px calc(50% - 50px)',
    },
  };

  const smartTeaser = {
    carRelease: {
      title: {
        font: `500 18px/22px ${fonts.display}`,
      },
      info: {
        font: `400 15px/22px ${fonts.display}`,
      },
    },
  };

  return deepmerge({
    controls: {
      vikontReadMore,
      scooter,
      shapka,
      expert,
      topicFooter,
      commentsPreview,
      topicTopline,
      topicHeaderTexts,
      vikontImage,
      storyCard,
      storyWidgetsBaseCard,
      smartTeaser,
    },
    pages,
    layout,
    texts,
  }, atoms);
}
