import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { rawContentFetcher } from 'core/fetchers/searchPage';

import SearchPage from 'core/components/SearchPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';
import withPageHocs from 'core/components/withPageHocs';

import Boroda from 'site/components/Boroda';
import { InnerSideColumnShort } from 'site/components/InnerSideColumn';

import { VerticalIndent } from 'site/components/Indents';

import {
  Context,
  SuperFooter,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  ListingSpecSearch,
} from 'site/components/Ads/mobile';

import Card7 from 'site/cards/Card7';

import filters from './filters';


/**
 * TODO: проверить соответствие ссылок на топики заголовкам и тексту
 * результатов.
 */
function Search({ rawContent }) {
  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumnShort />}>
        <SearchPage
          rawContent={rawContent}
          filters={filters}
          ad1={SuperFooter}
          ad2={Context}
          ad1Mobile={Listing1}
          ad2Mobile={ListingSpecSearch}
          card={Card7}
        />
      </ColumnLayout>
      <VerticalIndent />
      <Boroda />
      <VerticalIndent />
    </PageIndent>
  );
}

Search.propTypes = {
  rawContent: PropTypes.array,
};

const dataProvider = resolve({
  rawContent: rawContentFetcher({ card: Card7 }),
});

export default withPageHocs(dataProvider)(Search);
