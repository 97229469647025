import PropTypes from 'prop-types';

import Mjolnir from 'core/components/Mjolnir';
import { withBreakpoint } from 'core/components/breakpoint';

import { Card1Type1 } from 'site/cards/Card1';
import { Card4Type1 } from 'site/cards/Card4';
import { Card3Type5 } from 'site/cards/Card3';

/**
 * Компонент предназначен для отображения топиков листа `column_1` на главной.
 */
function Column1ListTopics({ content, isDesktop }) {
  return (
    <Mjolnir
      content={content}
      dataQa='column1-list'
      heroCard={Card1Type1}
      card={isDesktop ? Card4Type1 : Card3Type5}
      interitemSpacing={isDesktop ? 30 : 15}
    />
  );
}

Column1ListTopics.propTypes = {
  content: PropTypes.array,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(Column1ListTopics);
