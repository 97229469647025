import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { get } from 'core/libs/lodash';
import color from 'core/libs/color';
import bindProps from 'core/components/bindProps';
import cardHOC from 'core/components/card';

import Link from 'core/components/Link';
import Divider from 'core/components/Divider';
import SmartImage from 'core/components/SmartImage';
import Button from 'core/components/Button';

import styles from './index.styl';

import relationshipsPropTypes, {
  imagePropTypes,
} from 'core/utils/prop-types/relationships';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import resolveRelationships from 'core/utils/relationships';

import mapTypes from './types';

const requiredPayloadImports = ['image'];

const requiredPayloadFields = [
  'link',
  'headline',
  'alternative_headline',
  'list_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

function Card1(props) {
  const {
    content,
    breakpoint,
    theme,
    type,
    highPriorityImage,
  } = props;

  const {
    link,
    headline,
    alternative_headline: altHeadline,
    list_headline: listHeadline,
  } = content.attributes;

  const {
    image: {
      versions: {
        main: cover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const {
    showButton,
    imageWidth,
    imageHeight,
    skewedSides,
  } = get(mapTypes, `${type}.${breakpoint}`, mapTypes[type] || {});

  const transparentLayout = color(theme.colors.layout).alpha(0).string();

  return (
    <Fragment>
      <div
        className={cx(
          'card1',
          styles.card1,
          styles[`_type${type}`],
          showButton && styles._showButton,
        )}
        data-qa='card1'
      >
        <style jsx>{`
          .${styles.card1}
            :global(.desktop) &
              background ${theme.colors.layout}
              border 4px solid ${theme.colors.layout}
              max-height ${imageHeight}px

          .${styles.image}
            max-width ${imageWidth - skewedSides}px
            :global(.mobile) &
              border 4px solid ${theme.colors.layout}

          .${styles.content}
            padding-bottom ${showButton ? '150px' : '0'}
          :global(.desktop) .${styles._type0} .${styles.content}:after
            background linear-gradient(180deg, ${transparentLayout}, ${theme.colors.layout} 40%)
        `}</style>

        <Link
          to={link}
          type='secondary'
          className={styles.link}
        >
          <div className={styles.content} data-qa='card1-content'>
            <div className={styles.headline} data-qa='card1-headline'>
              {listHeadline || headline}
            </div>
            {altHeadline &&
              <div className={styles.altHeadline}>
                {altHeadline}
              </div>
            }
            {showButton && (
              <Button
                type='secondary'
                size='small'
                className={styles.button}
                data-qa='card1-button'
              >
                Смотреть
              </Button>
            )}
          </div>
          <div className={styles.image}>
            <div className={styles.imageContent}>
              <SmartImage
                src={cover}
                width={imageWidth}
                height={imageHeight}
                previewSrc={previewCover}
                position='50% 0'
                {...highPriorityImage && { fetchPriority: 'high', instant: true }}
              />
            </div>
          </div>
        </Link>
      </div>
      <Divider className={styles.divider} />
    </Fragment>
  );
}

Card1.defaultProps = {
  type: 0,
};

Card1.propTypes = {
  content: relationshipsPropTypes({
    image: imagePropTypes(),
  }),
  breakpoint: breakpointPropTypes(),
  isDesktop: PropTypes.bool,
  theme: PropTypes.object,
  type: PropTypes.oneOf([0, 1]),
  /**
   * Использование fetchPriority для загрузки картинки
   */
  highPriorityImage: PropTypes.bool,
};

const Card = cardHOC(Card1);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export const Card1Type1 = bindProps({ type: 1 })(Card);

export default Card;
