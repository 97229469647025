import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import GlobalStyles from 'site/components/Ads/GlobalStyles';
import './index.styl';

const NativeStyles = ({ theme }) => (
  <Fragment>
    <style global jsx>{`
      .native-card3
        .ad_native_title
          color ${theme.colors.primary}
          font 16px/1.25 ${theme.fonts.display}
        .ad_label__text
          color ${theme.colors.active2}
          font 12px/1 ${theme.fonts.nuance}
    `}</style>
  </Fragment>
);

NativeStyles.propTypes = {
  theme: PropTypes.object,
};

export default function NativeCard3(Component) {
  return withTheme(props => (
    <div className='adf-wrapper native-card3'>
      <GlobalStyles {...props} />
      <NativeStyles {...props} />
      <Component {...props} />
    </div>
  ));
}
