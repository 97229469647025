import Block from 'core/components/GridBlock';
import { withBreakpoint } from 'core/components/breakpoint';

export const BLOCK_VERTICAL = 40;
export const BLOCK_VERTICAL_MOBILE = 20;

export const BLOCK_HORIZONTAL = 55;
export const BLOCK_HORIZONTAL_MOBILE = 20;

export const CONTENT_VERTICAL = 20;
export const CONTENT_HORIZONTAL = 40;

export function SideIndent(props) {
  return (
    <Block
      width={`${BLOCK_HORIZONTAL_MOBILE}px`}
      desktop={`${BLOCK_HORIZONTAL}px`}
      {...props}
    />
  );
}

export const NegativeMobileSideIndent = withBreakpoint(({ isDesktop, children }) => {
  if (isDesktop) return children;

  return (
    <div style={{ margin: `0 -${BLOCK_HORIZONTAL_MOBILE}px` }}>
      {children}
    </div>
  );
});

export function InnerIndent(props) {
  return (
    <Block
      width={0}
      desktop={`${BLOCK_HORIZONTAL}px`}
      {...props}
    />
  );
}

export const VerticalIndent = withBreakpoint(({ isMobile }) => (
  <div style={{ marginTop: isMobile ? BLOCK_VERTICAL_MOBILE : BLOCK_VERTICAL }} />
));

export function ContentVerticalIndent() {
  return (
    <div style={{ marginTop: CONTENT_VERTICAL }} />
  );
}
