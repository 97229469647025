import React from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import H1 from 'core/components/H1';

import styles from './index.styl';

function Highlight(props) {
  const {
    title,
    children,
    headerComponent: Header,
    indent,
    theme,
  } = props;

  return (
    <div className={styles.highlight}>
      <style jsx>{`
        .${styles.highlight}
          background ${theme.colors.accent}
          margin-top ${indent * 2 + 'px'}
          padding 0 ${indent + 'px'} ${indent + 'px'}

        .caption
          margin ${'-' + Math.abs(indent) + 'px'} 0 ${indent + 'px'}
      `}</style>

      <div className='caption'>
        <Header>{title}</Header>
      </div>
      <div>
        {children}
      </div>
    </div>
  );
}

Highlight.propTypes = {
  title: PropTypes.node,
  indent: PropTypes.number,
  headerComponent: PropTypes.func,
  theme: PropTypes.object,
};

Highlight.defaultProps = {
  indent: 30,
  headerComponent: H1,
};

export default withTheme(Highlight);
