import TopicGallery from 'site/components/TopicGallery';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

export default function GalleryCover({ content }) {
  const {
    photo_gallery: {
      data: photoGallery,
    },
  } = content.relationships;

  return <TopicGallery photoGallery={photoGallery} />;
}

GalleryCover.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
};
