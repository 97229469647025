import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'core/libs/helmet';

import resolve from 'core/resolver/resolve';

import { topicsFetcher } from 'core/fetchers/topics';

import { filterRequiredParams } from 'core/utils/api';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import withTheme from 'core/components/theme';
import { withBreakpoint, Desktop } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import Page from 'core/components/Page';
import Feed from 'core/components/Feed';
import Bouesque from 'core/components/Bouesque';
import StickyPortal from 'core/components/StickyPortal';
import SectionTitle from 'site/components/SectionTitle';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';
import Skeleton from 'site/pages/rubric/Skeleton';

import {
  VerticalIndent,
  ContentVerticalIndent,
} from 'site/components/Indents';
import SelfPromo from 'core/components/SelfPromo';

import {
  AdPlace2,
  AdPlace3,
} from '../components/Ads/desktop';

import SkewedContainer from 'site/components/SkewedContainer';
import Boroda from 'site/components/Boroda';

import withStarLink from 'site/components/withStarLink';

import { Card3Type1 } from 'site/cards/Card3';

/**
 * Нарезаем данные о топиках по группам в двумерный массив.
 * Данные в topicsAlphabet, отсортированные по алфавиту, по полю headline.
 * Собираем подгруппы из topicsAlphabet, с ограничением по groupSize.
 */
function splitInGroups(list, groupSize) {
  const [first, ...items] = list;

  return items.reduce((result, item) => {
    const group = result[result.length - 1];
    const itemChar = item.attributes.headline[0].toLowerCase();
    const groupChar = group[0].attributes.headline[0].toLowerCase();

    if (itemChar === groupChar) {
      if (group.length < groupSize) group.push(item);
    } else {
      result.push([item]);
    }
    return result;
  }, [[first]]);
}

const starsCard = withStarLink(Card3Type1);

function StarsPage(props) {
  const {
    theme,
    isMobile,
    topicsPopular,
    topicsAlphabet,
  } = props;

  const topicsGroups = splitInGroups(topicsAlphabet, 6);

  return (
    <Page
      title='Все звезды шоу-бизнеса и кино, новости, биографии, факты на WMJ'
      description='Все звезды отечественного и зарубежного шоу-бизнеса и кино, биографии звезд, новости, интересные факты о звездах.'
    >
      <style jsx>{`
        .item
          & + &
            margin-top 64px
      `}</style>
      <style jsx>{`
        .popular
          margin-top ${isMobile ? -50 : -160}px

        .itemTitle
          font ${theme.texts.title1.font}
      `}</style>
      <Helmet titleTemplate={null} />

      <Desktop>
        <PageIndent>
          <ColumnLayout rightColumn={<Bouesque />} />
        </PageIndent>
      </Desktop>

      <SkewedContainer color={theme.colors.accent}>
        <div className='popular'>
          <PageIndent>
            <ColumnLayout rightColumn={<AdPlace2 />}>
              <Fragment>
                <SectionTitle htmlTag='h1'>Звезды</SectionTitle>
                <ContentVerticalIndent />
                <SectionTitle level={3} htmlTag='h3'>Популярные звезды</SectionTitle>
                <VerticalIndent />
                <Feed
                  interitemSpacing={30}
                  card={starsCard}
                  columns={2}
                  grid
                  content={topicsPopular}
                />
              </Fragment>
            </ColumnLayout>
          </PageIndent>
        </div>
      </SkewedContainer>

      <PageIndent>
        <ColumnLayout
          rightColumn={(
            <Fragment>
              <SelfPromo />
              <VerticalIndent />
              <StickyPortal>
                <AdPlace3 />
              </StickyPortal>
            </Fragment>
          )}
        >
          <Fragment>
            {topicsGroups.map((item, index) => (
              <div className='item' key={index}>
                <div className='itemTitle'>{item[0].attributes.headline.charAt(0)}</div>
                <ContentVerticalIndent />
                <Feed
                  interitemSpacing={30}
                  card={starsCard}
                  columns={2}
                  grid
                  content={item}
                />
              </div>
            ))}
          </Fragment>
        </ColumnLayout>
        <VerticalIndent />
        <Boroda />
        <VerticalIndent />
      </PageIndent>
    </Page>
  );
}

StarsPage.propTypes = {
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
  topicsPopular: PropTypes.arrayOf(modelPropTypes(topicAttributes).isRequired),
  topicsAlphabet: PropTypes.arrayOf(modelPropTypes(topicAttributes).isRequired),
};

const include = filterRequiredParams([starsCard], 'include');

const dataProvider = resolve({
  topicsPopular: topicsFetcher({
    limit: 6,
    include,
    topic_type: 'star',
    card: starsCard,
  }),

  topicsAlphabet: topicsFetcher({
    limit: 200,
    include,
    sort: 'headline',
    topic_type: 'star',
    card: starsCard,
  }),
});

export default withPageHocs(dataProvider, Skeleton)(withTheme(withBreakpoint(StarsPage)));
