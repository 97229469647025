import React from 'react';
import PropTypes from 'prop-types';

import TopicPages from 'core/components/TopicPages';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

/**
 * Презентер для компонента `TopicPages`
 * `TopicPages` имеет пагинацию по URL, без get парметров.
 *
 * @author Aleksey Dibrivniy <a.dibrivniy@rambler-co.ru>
 */

export default function SiteTopicPages({ topicBefore, topicAfter }) {
  const {
    headline: headlineBefore,
    link: linkBefore,
    meta: metaBefore,
  } = topicBefore;

  const {
    headline: headlineAfter,
    link: linkAfter,
    meta: metaAfter,
  } = topicAfter;

  const count = metaBefore.filtered_count + 1 + metaAfter.filtered_count;
  const active = count - metaAfter.filtered_count;

  function linkConstructor(current) {
    return current < active ? linkBefore : linkAfter;
  }

  return (
    <TopicPages
      active={active}
      total={count}
      linkConstructor={linkConstructor}
      prevTitle={<MarkdownWrapper children={headlineBefore} inline />}
      nextTitle={<MarkdownWrapper children={headlineAfter} inline />}
    />
  );
}

const topicAttributes = PropTypes.shape({
  headline: PropTypes.string,
  link: PropTypes.string,
  meta: PropTypes.shape({
    filtered_count: PropTypes.number,
  }),
});

SiteTopicPages.propTypes = {
  topicBefore: topicAttributes,
  topicAfter: topicAttributes,
};
