import PropTypes from 'prop-types';

import AboutCore from 'core/components/About';

import { aboutFetcher } from 'core/fetchers/about';

import resolve from 'core/resolver/resolve';

import withPageHocs from 'core/components/withPageHocs';

function AboutPage({ content }) {
  return (
    <AboutCore
      content={content}
      logo={<img width={229} src={require('./images/wmj_logo.png')} />}
    />
  );
}

AboutPage.propTypes = {
  content: PropTypes.object,
};

const dataProvider = resolve('content', aboutFetcher());

export default withPageHocs(dataProvider)(AboutPage);
