import Avatar from 'core/components/Avatar';
import Link from 'core/components/Link';
import pluralize from 'core/utils/pluralize';
import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { parenthesesPattern } from 'site/constants';

import { getFullYearsDelta } from 'site/utils';

import styles from './index.styl';

const defaultAvatar = {
  rel_url: require('site/images/user-avatar.png'),
};

const requiredPayloadImports = [
  'image',
  'person_meta',
];

const requiredPayloadFields = [
  'headline',
  'link',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

function CardBirthday(props) {
  const {
    content,
  } = props;

  if (!content) return null;

  const {
    headline,
    link,
  } = content.attributes;

  const {
    image: {
      versions: {
        star: cover,
      },
    },
    person_meta: {
      birth_date: birthDateString,
      death_date: deathDateString,
      zodiac: zodiac,
      zodiac_ru: zodiacRu,
    },
  } = relationships(content);

  const birthDate = new Date(birthDateString);
  const lastDate = deathDateString
    ? new Date(deathDateString)
    : new Date();
  const starAge = getFullYearsDelta(birthDate, lastDate);
  const starAgeYears = pluralize(starAge, ['год', 'года', 'лет']);

  const starHeadline = headline.replace(parenthesesPattern, '');

  const Icon = zodiac
    ? require(`site/components/Icon/Horoscope/${zodiac}`).default
    : () => null;

  return (
    <Link
      to={link}
      type='secondary'
      className={styles.link}
    >
      <div className={styles.headline}>
        {starHeadline}
      </div>
      <div className={styles.imageWrapper}>
        <Avatar
          isCircular
          src={cover || defaultAvatar}
          size={100}
        />
        <Icon className={styles.icon} />
      </div>
      <div className={styles.info}>
        {`${starAge} ${starAgeYears}, `}
        <span className={styles.zodiacSign}>{zodiacRu}</span>
      </div>
    </Link>
  );
}

CardBirthday.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
};

CardBirthday.requiredPayloadImports = requiredPayloadImports;
CardBirthday.requiredPayloadFields = requiredPayloadFields;

export default CardBirthday;
