import { Fragment } from 'react';
import PropTypes from 'prop-types';

import themePropTypes from 'core/utils/prop-types/theme';

import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

function Divider({ theme, isMobile, top, bottom }) {
  return (
    <Fragment>
      <style jsx>{`
        .divider
          height 1px
      `}</style>

      <style jsx>{`
        .divider
          margin-top ${top + 'px'}
          margin-bottom ${bottom + 'px'}
          width ${isMobile ? '60px' : '130px'}
          background-color ${theme.colors.divider}
      `}</style>

      <div className='divider'>{' '}</div>
    </Fragment>
  );
}

Divider.propTypes = {
  top: PropTypes.number,
  bottom: PropTypes.number,
  isMobile: PropTypes.bool,
  theme: themePropTypes(`
    {
      colors: {
        divider,
      },
    }
  `),
};

Divider.defaultProps = {
  top: 10,
  bottom: 43,
};

export default withBreakpoint(withTheme(Divider));
