import { Fragment } from 'react';
import PropTypes from 'prop-types';
import queryString from 'core/libs/query-string';

import { withRouter } from 'core/libs/router';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';

import { Mobile, Desktop } from 'core/components/breakpoint';

import CustomCover from './Custom';
import VideoCover from './Video';
import GalleryCover from './Gallery';

import {
  NegativeMobileSideIndent,
} from 'site/components/Indents';

const relationships = resolveRelationships(
  ['content'],
  {},
  { content: { widgets: [] } },
);

const coverTypeMapping = {
  news: CustomCover,
  article: CustomCover,
  gallery: GalleryCover,
  video: VideoCover,
};

/**
 * Компонент отвечает за отрисовку обложки, одного из типов: картинка, галерея, видео и т.д.
 *
 * @author Aleksey Dibrivniy <a.dibrivniy@rambler-co.ru>
 */
function TopicMedia({ content, location }) {
  const {
    attributes: {
      topic_type: topicType,
    },
  } = content;

  const {
    content: {
      widgets,
    },
  } = relationships(content);

  const { search } = location;
  const { page: pageNum } = queryString.parse(search);

  const activePageIndex = (pageNum || 1) - 1;
  const pageBreaks = (widgets || [])
    .filter(widget => widget.type === 'pageBreak');

  const isNextPage = Boolean(activePageIndex)
    && activePageIndex <= pageBreaks.length;

  const Cover = coverTypeMapping[topicType] || null;

  if (isNextPage) {
    return null;
  }

  return (
    <Fragment>
      <Mobile>
        <NegativeMobileSideIndent>
          <Cover content={content} />
        </NegativeMobileSideIndent>
      </Mobile>
      <Desktop>
        <Cover content={content} />
      </Desktop>
    </Fragment>
  );
}

TopicMedia.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(TopicMedia);
