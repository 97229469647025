import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import dataProvider from 'core/resolver/data/topic';

import withPageHocs from 'core/components/withPageHocs';
import Topic from 'core/components/Topic';
import Link from 'core/components/Link';
import Button from 'core/components/Button';
import Comments from 'core/components/Comments';
import Feed from 'core/components/Feed';
import { Desktop, withBreakpoint } from 'core/components/breakpoint';
import bindProps from 'core/components/bindProps';
import Socializator from 'core/components/Socializator';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import TopicH1 from 'site/components/TopicH1';
import Boroda from 'site/components/Boroda';
import TopicHeader from 'site/components/TopicHeader';
import Hightlight from 'site/components/Hightlight';
import { InnerSideColumnShort } from 'site/components/InnerSideColumn';

import Card5 from 'site/cards/Card5';

import { VerticalIndent } from 'site/components/Indents';

import {
  Context,
  SuperFooter,
} from 'site/components/Ads/desktop';
import { getUtm } from 'site/constants';
import styles from './index.styl';

const relationships = resolveRelationships(
  ['read_more'],
  {},
  { read_more: { topic_ids: [] } },
);


function CommentsPage({ content: topic, isMobile, breakpoint }) {
  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumnShort />}>
        <Topic content={topic} >
          {content => {
            const {
              id,
              attributes: {
                link,
                is_comments_disabled: isCommentsDisabled,
              },
            } = content;

            const commProps = { xid: id, isCommentsDisabled };

            const {
              read_more: {
                topic_ids: ids,
              },
            } = relationships(content);

            return (
              <Fragment>
                <TopicHeader content={content} />
                <div className={styles.wrapper}>
                  <Link
                    to={link}
                    className={styles.link}
                    type='secondary'
                  >
                    <Button type='secondary'>Вернуться к статье</Button>
                  </Link>
                  <div className={styles.share}>
                    <Socializator />
                  </div>
                </div>
                <Comments {...commProps} />
                <VerticalIndent />
                <Desktop>
                  <SuperFooter />
                </Desktop>
                <VerticalIndent />
                {
                  ids.length > 0 && (
                    <Fragment>
                      <Hightlight
                        title='Ещё по теме'
                        indent={isMobile ? 15 : 30}
                        headerComponent={TopicH1}
                      >
                        <Feed
                          interitemSpacing={isMobile ? 20 : 30}
                          ids={ids.join(',')}
                          card={bindProps({ imageMaxWidth: 364, utm: getUtm(breakpoint) })(Card5)}
                          columns={2}
                          grid
                        />
                      </Hightlight>
                      <VerticalIndent />
                    </Fragment>
                  )
                }
                <Context />
              </Fragment>
            );
          }}
        </Topic>
      </ColumnLayout>
      <Boroda />
      <VerticalIndent />
    </PageIndent>
  );
}

CommentsPage.propTypes = {
  content: PropTypes.object,
  isMobile: PropTypes.bool,
  breakpoint: breakpointPropTypes(),
};

export default withBreakpoint(withPageHocs(dataProvider)(CommentsPage));
