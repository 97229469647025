import React, { Fragment } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import GlobalStyles from 'site/components/Ads/GlobalStyles';
import styles from './index.styl';

const NativeStyles = ({ theme }) => (
  <Fragment>
    <style global jsx>{`
      .adf-wrapper-card4
        .ad_label__text
          font ${theme.texts.caption1.font}

        .ad_native_title
          font-family ${theme.fonts.display}

        .ad_native_desc
          font-family ${theme.fonts.text}

        .ad_native_img
          border 3px solid ${theme.colors.layout}
    `}</style>
  </Fragment>
);

NativeStyles.propTypes = {
  theme: PropTypes.object,
};

export default function card4Styles(Component) {
  return withTheme(props => (
    <div className={cx(styles['adf-wrapper-card4'], 'adf-wrapper adf-wrapper-card4')}>
      <GlobalStyles {...props} />
      <NativeStyles {...props} />

      <Component {...props} />
    </div>
  ));
}
