import { rawTopicsFetcher } from 'core/fetchers/rubric';

import { filterRequiredParams, denormalizeData } from 'core/utils/api';

import Card5 from 'site/cards/Card5';

import { TOPICS_LIMIT } from 'site/constants';


const include = filterRequiredParams([Card5], 'include');

export default (customParams) => (componentProps) => {
  const props = { ...componentProps, ...customParams };
  return rawTopicsFetcher({
    limit: TOPICS_LIMIT,
    include,
    ...props,
  })(props)
    .then(data => ({
      content: props.content || denormalizeData(data),
      meta: props.meta || data.meta,
    }));
};
