import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import withPageHocs from 'core/components/withPageHocs';

import RubricPageByType from 'site/components/RubricPageByType';
import ContentLayout from 'site/components/ContentLayout';

import rubricPageFetcher from 'site/fetchers/rubricByType';

import Skeleton from 'site/pages/rubric/Skeleton';

import tags from './tags';


function NewsPage({ data }) {
  return (
    <ContentLayout
      tags={tags}
      title='Новости'
      metaTitle='Женские новости на WMJ'
      metaDescription='Женские новости: красота, мода, здоровье, любовь и секс на WMJ'
    >
      <RubricPageByType data={data} />
    </ContentLayout>
  );
}

NewsPage.propTypes = {
  data: PropTypes.object,
};

const dataProvider = resolve({
  data: rubricPageFetcher({ topic_type: 'news' }),
});

export default withPageHocs(dataProvider, Skeleton)(NewsPage);
