import PropTypes from 'prop-types';

import styles from './index.styl';

import modelPropTypes, {
  numberAttributes,
} from 'core/utils/prop-types/model';

import MarkdownWrapper from 'core/components/MarkdownWrapper';

/**
 * @author Evgeny Shiryaev <e.shiryaev@rambler-co.ru>
 */

export default function Number(props) {
  const {
    number,
    parentProps,
  } = props;

  const {
    attributes: {
      body,
      value,
      unit,
    },
  } = number;

  const {
    theme,
  } = parentProps;

  const vicontText = unit ? `${unit} ${body}` : body;

  return (
    <div className={styles.vikont}>
      <style jsx>{`
        .${styles.value}
          font-family ${theme.fonts.nuance}
      `}</style>

      <div className={styles.value}>
        {value}
      </div>
      <div className={styles.text}>
        <MarkdownWrapper>
          {vicontText}
        </MarkdownWrapper>
      </div>
    </div>
  );
}

Number.propTypes = {
  number: modelPropTypes(numberAttributes).isRequired,
  parentProps: PropTypes.object,
};
