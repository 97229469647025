import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import cardHOC from 'core/components/card';
import bindProps from 'core/components/bindProps';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import VideoIcon from 'site/icons/Video';

import styles from './index.styl';

import relationshipsPropTypes, {
  imagePropTypes,
} from 'core/utils/prop-types/relationships';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import resolveRelationships from 'core/utils/relationships';

import mapTypes from './types';
import mapSizes from './sizes';

const requiredPayloadImports = [
  'image',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'alternative_headline',
  'topic_type',
  'list_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

function Card4(props) {
  const {
    type,
    content,
    theme,
  } = props;

  if (!content) return null;

  const {
    withAltHeadline,
    imageMaxWidth,
    imageMaxHeight,
    imageVersion,
    size,
  } = mapTypes[type] || {};

  const {
    headline: {
      fontSize: headlineFontSize,
      lineHeight: headlineLineHeight,
    },
    altHeadline: {
      fontSize: altHeadlineFontSize,
      lineHeight: altHeadlineLineHeight,
    },
  } = mapSizes[size || 'md'] || {};

  const {
    link,
    headline,
    alternative_headline: altHeadline,
    list_headline: listHeadline,
    topic_type: topicType,
  } = content.attributes;

  const {
    image: {
      versions: {
        [imageVersion]: cover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const {
    remote_image: remoteImage,
  } = content.extra || {};

  const isVideo = topicType === 'video';

  return (
    <div className={cx(
      'card4',
      styles.card4,
      withAltHeadline && styles.withAltHeadline,
    )} data-qa='card4'
    >
      <style jsx>{`
        .${styles.card4}
          max-width ${imageMaxWidth}px
        .${styles.image}
          border 3px solid ${theme.colors.layout}

        .${styles.headline}
          font-size ${headlineFontSize}
          line-height ${headlineLineHeight}

        .${styles.altHeadline}
          font-size ${altHeadlineFontSize}
          line-height ${altHeadlineLineHeight}
      `}</style>

      <Link
        to={link}
        type='secondary'
        className={styles.link}
        data-qa='card4-link'
      >
        <div className={styles.image}>
          <Image
            {...cover && { src: cover }}
            {...remoteImage && { url: remoteImage }}
            {...cover && { previewSrc: previewCover }}
            maxWidth={imageMaxWidth}
            height={imageMaxHeight}
            position='50% 0'
          />
          {isVideo && (
            <VideoIcon
              width='32'
              height='32'
              className={styles.icon}
            />
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.headline}>
            {listHeadline || headline}
          </div>
          {withAltHeadline && (
            <div className={styles.altHeadline}>
              {altHeadline}
            </div>
          )}
        </div>
      </Link>
    </div>
  );
}

Card4.propTypes = {
  type: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
  content: relationshipsPropTypes({
    image: imagePropTypes(),
  }),
  breakpoint: breakpointPropTypes(),
  theme: PropTypes.object,
};

Card4.defaultProps = {
  type: 0,
};

const Card = cardHOC(Card4);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export const Card4Type1 = bindProps({ type: 1 })(Card);
export const Card4Type2 = bindProps({ type: 2 })(Card);
export const Card4Type3 = bindProps({ type: 3 })(Card);
export const Card4Type4 = bindProps({ type: 4 })(Card);
export const Card4Type5 = bindProps({ type: 5 })(Card);

export default Card;
