import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import Drum from 'core/components/Drum';
import bindProps from 'core/components/bindProps';

import SectionTitle from 'site/components/SectionTitle';
import SkewedRaker from 'site/components/SkewedRaker';
import Card5 from 'site/cards/Card5';

import styles from './index.styl';


const HeaderComponent = bindProps({ level: 1, align: 'center', className: styles.sectionTitle })(SectionTitle);
const GalleryCard = bindProps({ imageMaxWidth: 252, imageMaxHeight: 170 })(Card5);

const GalleryDrumRaker = ({ content, isDesktop }) =>
  isDesktop ? (
    <div data-qa='gallery'>
      <SkewedRaker
        content={content}
        color='#000'
        sidebarColumnCount={2}
        dataQa='gallery-raker'
      />
    </div>
  ) : (
    <Drum
      content={content}
      title='Фото'
      headerComponent={HeaderComponent}
      card={GalleryCard}
      spaceBetween={15}
      itemWidthMobile={252 + 15}
      dataQa='gallery-drum'
    />
  );

GalleryDrumRaker.propTypes = {
  content: PropTypes.array,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(GalleryDrumRaker);
