import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import cardHOC from 'core/components/card';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import Shop from 'site/icons/Shop';

import styles from './index.styl';

import relationshipsPropTypes, {
  imagePropTypes,
} from 'core/utils/prop-types/relationships';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import resolveRelationships from 'core/utils/relationships';

const requiredPayloadImports = [
  'image',
  'shop_meta',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  shop_meta: {},
});

function CardProduct(props) {
  const {
    content,
    theme,
  } = props;

  const {
    link,
    headline,
    list_headline: listHeadline,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
    shop_meta: {
      price,
    },
  } = relationships(content);

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.link}
          background ${theme.colors.layout}
        .${styles.price}
          font-family ${theme.fonts.nuance}
      `}</style>
    </scope>
  );

  return (
    <div className={cx(
      'cardProduct',
      styles.cardProduct,
    )}
    >
      <Link
        to={link}
        type='secondary'
        className={scoped.wrapClassNames(styles.link)}
      >
        <div className={styles.headline}>
          {listHeadline || headline}
        </div>
        <div className={styles.image}>
          <Image
            {...cover && { src: cover }}
            {...cover && { previewSrc: previewCover }}
            width={200}
            height={200}
            position='50% 0'
          />
        </div>
        {price && (
          <div className={scoped.wrapClassNames(styles.price)}>
            <Shop width='14' height='18' />{`${price}`.replace(/руб\./g, '₽')}
          </div>
        )}
      </Link>
      <scoped.styles />
    </div>
  );
}

CardProduct.propTypes = {
  imageMaxWidth: PropTypes.number,
  imageMaxHeight: PropTypes.number,
  type: PropTypes.oneOf([0, 1]),
  content: relationshipsPropTypes({
    image: imagePropTypes(),
  }),
  breakpoint: breakpointPropTypes(),
  isDesktop: PropTypes.bool,
  theme: PropTypes.object,
};


const Card = cardHOC(CardProduct);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;
