import Breadcrumbs from 'core/components/Breadcrumbs';

import { rubricAttributes } from 'core/utils/prop-types/model';

function TopicBreadcrumbs(props) {
  const {
    root_slug: rootSlug,
    slug,
  } = props.rubric || {};

  return (
    <Breadcrumbs
      root_slug={rootSlug}
      rubric_slug={slug || rootSlug}
    />
  );
}

TopicBreadcrumbs.propTypes = {
  rubric: rubricAttributes,
};

export default TopicBreadcrumbs;
