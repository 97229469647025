import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ThemeSwitch from 'core/components/ThemeSwitch';
import Button from 'core/components/Button';
import H1 from 'core/components/H1';
import Link from 'core/components/Link';
import Swipe, { Slide } from 'core/components/Swipe';

import { withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';

import resolveRelationships from 'core/utils/relationships';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';
import { dateFormat } from 'core/utils/dates';

import { VerticalIndent } from 'site/components/Indents';

import Divider from 'site/components/Divider';
import TopicContent from 'site/components/TopicContent';
import SameZodiacStars from 'site/components/SameZodiacStars';
import StarsBirthday from 'site/components/StarsBirthday';

import beautyButtonImage from './button-horoscope-beauty.jpg';
import fashionButtonImage from './button-horoscope-fashion.jpg';

import styles from './index.styl';

const horoscopeShape = {
  horoscope: {
    birth_dates: [],
    related_links: {},
  },
};

const relationships = resolveRelationships(...[
  ['horoscope'],
  {},
  horoscopeShape,
]);

const buttonData = [
  {
    title: 'Модный гороскоп',
    link: '/label/modnyj-goroskop',
    backgroundImage: fashionButtonImage,
  }, {
    title: 'Гороскоп красоты',
    link: '/label/goroskop-krasoty',
    backgroundImage: beautyButtonImage,
  },
];

function HoroscopeTopic({ content, sameZodiacTopics, isMobile, theme }) {
  const {
    horoscope: {
      birth_dates: birthDates,
      zodiac,
      zodiac_ru: zodiacRu,
      related_links: {
        yesterday_horoscope: linkYesterday,
        today_horoscope: linkToday,
        tomorrow_horoscope: linkTomorrow,
        week_horoscope: linkWeek,
        month_horoscope: linkMonth,
      },
    },
  } = relationships(content);

  const {
    attributes: {
      headline,
      link: currentLink,
    },
  } = content;

  const periodLinks = [
    {
      title: 'Вчера',
      link: linkYesterday,
    }, {
      title: 'Сегодня',
      link: linkToday,
    }, {
      title: 'Завтра',
      link: linkTomorrow,
    }, {
      title: 'На неделю',
      link: linkWeek,
    }, {
      title: 'На месяц',
      link: linkMonth,
    },
  ];

  const Icon = zodiac
    ? require(`site/components/Icon/Horoscope/${zodiac}`).default
    : () => null;

  const period = birthDates.map(date => dateFormat(date, 'd MMMM')).join(' - ');

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.titleIcon}
          & :global([id*="$"])
            fill ${theme.colors.primary}

        .title2
          font ${theme.texts.title2.font}
          color ${theme.texts.title2.color}
      `}</style>
    </scope>
  );

  return (
    <div data-qa='topic'>
      <style jsx>{`
        .${styles.titleIconWrapper}
          background-image linear-gradient(50deg, ${theme.colors.active2}, ${theme.colors.active1})

        .${styles.titlePeriod}
          color ${theme.colors.primary}

        .${styles.titleHeadline}
          font ${theme.texts.title1.font}
          color ${theme.texts.title1.color}
      `}</style>
      <div className={styles.titleWrapper}>
        <div className={styles.titleIconWrapper}>
          <Icon className={scoped.wrapClassNames(styles.titleIcon)} />
        </div>
        <div className={styles.titleContent}>
          <span className={styles.titleHeadline} data-qa='horoscope-title-headline'>{zodiacRu}</span>
          <div className={styles.titlePeriod} data-qa='horoscope-title-period'>{period}</div>
        </div>
      </div>
      <VerticalIndent />
      {renderPeriodLinks(periodLinks, currentLink, isMobile)}
      <VerticalIndent />
      <H1 className={scoped.wrapClassNames('title2')}>{headline}</H1>
      <VerticalIndent />
      <TopicContent content={content} />
      <VerticalIndent />
      <SameZodiacStars
        period={period}
        zodiac={zodiac}
        content={sameZodiacTopics}
      />
      <Divider />
      <ThemeSwitch name='dark'>
        {buttonData.map(item => (
          <div className={styles.passionLinkWrapper} key={item.link}>
            <Link to={item.link}>
              <Button background={`url(${item.backgroundImage}) 0 0 / cover`} type='ghost'>
                {item.title}
              </Button>
            </Link>
          </div>
        ))}
      </ThemeSwitch>
      <VerticalIndent />
      {isMobile && (
        <Fragment>
          <StarsBirthday />
          <VerticalIndent />
        </Fragment>
      )}
      <scoped.styles />
    </div>
  );
}

function renderPeriodLinks(links, currentLink, swipeable) {
  const Wrapper = swipeable ? Swipe : 'div';
  const LinkWrapper = swipeable ? Slide : 'span';

  const renderedLinks = links.map(item => {
    if (item.link === currentLink) {
      return (
        <LinkWrapper key={item.link}>
          <Button
            disabled
            size='small'
            state='active'
            type='secondary'
          >
            {item.title}
          </Button>
        </LinkWrapper>
      );
    }
    return (
      <LinkWrapper key={item.link}>
        <Link to={item.link}>
          <Button size='small' type='ghost'>
            {item.title}
          </Button>
        </Link>
      </LinkWrapper>
    );
  });

  return (
    <Wrapper>
      {renderedLinks}
    </Wrapper>
  );
}

HoroscopeTopic.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  sameZodiacTopics: PropTypes.array,
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(HoroscopeTopic));
