import React from 'react';
import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import TopicHeaderTexts from 'core/components/TopicHeaderTexts';
import TopicTopline from 'core/components/TopicTopline';
import withTheme from 'core/components/theme';
import { Mobile, Desktop } from 'core/components/breakpoint';

import { ContentVerticalIndent } from 'site/components/Indents';

import styles from './index.styl';

function TopicHeader(props) {
  const {
    center,
    content,
    theme: {
      colors: {
        hint: hintColor,
      },
      texts: {
        caption1: caption1Atoms,
      },
    },
  } = props;

  const {
    attributes: {
      is_premium: isPremium,
    },
  } = content;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .title
        .subtitle
          font-size 48px
          line-height 60px

        .lead
          max-width 860px
          margin-left auto
          margin-right auto
      `}</style>
    </scope>
  );

  return (
    <div>
      <style jsx>{`
        .${styles.topicHeader}
          justify-content ${center ? 'center' : 'flex-start'}
          font ${caption1Atoms.font}

        .${styles.date}
        .${styles.advert}
          color ${hintColor}
      `}</style>

      <TopicTopline center={center} />
      <ContentVerticalIndent />
      <div className={styles.topicTitleWrapper}>
        <Mobile>
          <TopicHeaderTexts />
        </Mobile>
        <Desktop>
          <TopicHeaderTexts {...isPremium && { scopedStyles: scope }} />
        </Desktop>
      </div>
      <scope.styles />
    </div>
  );
}

TopicHeader.propTypes = {
  center: PropTypes.bool,
  content: modelPropTypes(topicAttributes).isRequired,
  theme: PropTypes.object,
};

export default withTheme(TopicHeader);
