import React from 'react';
import PropTypes from 'prop-types';

import color from 'core/libs/color';
import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import H3 from 'core/components/H3';
import Feed from 'core/components/Feed';
import Image from 'core/components/Image';
import Text from 'core/components/Text';
import Button from 'core/components/Button';
import Link from 'core/components/Link';
import { Block, Section } from 'core/components/Grid';
import Socializator from 'core/components/Socializator';
import bindProps from 'core/components/bindProps';

import TopicContent from 'site/components/TopicContent';
import TopicPages from 'site/components/TopicPages';
import Basket from 'site/components/Icon/basket';
import TopicH1 from 'site/components/TopicH1';
import Comments from 'site/components/TopicComments';

import Card3 from 'site/cards/Card3';

import { ContentVerticalIndent, VerticalIndent } from 'site/components/Indents';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import { getUtm } from 'site/constants';
import styles from './index.styl';

const relationships = resolveRelationships(
  ['image', 'shop_meta', 'read_more'],
  {},
  { image: { versions: {} } }
);

function WishlistTopic({
  content,
  topicBefore,
  topicAfter,
  theme,
  isMobile,
  breakpoint,
}) {
  const {
    id,
    attributes: {
      headline,
      announce,
      link,
    },
  } = content;

  const {
    colors: { active2 },
  } = theme;

  const spacing = isMobile ? 10 : 20;
  const columns = isMobile ? 1 : 2;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
      caption,
    },
    shop_meta: {
      price,
      link: linkPrice,
    },
    read_more: {
      topic_ids: topicIds,
    },
  } = relationships(content);

  const altAndTitle = caption || headline;
  const Card3Type2 = bindProps({ type: 2, utm: getUtm(breakpoint) })(Card3);

  const priceBg = `linear-gradient(75deg, ${color(active2).alpha(0.7).string()} 0%, ${active2} 100%)`;
  const commProps = { xid: id, link };

  return (
    <div data-qa='topic'>
      <style jsx>{`
        .price
          font ${theme.texts.headline.font}
          color ${theme.colors.active2}
      `}</style>

      <TopicH1>{headline}</TopicH1>
      <ContentVerticalIndent />
      <Socializator />
      <VerticalIndent />
      <Section>
        <Block width={6} mobile={12}>
          <Text>{announce}</Text>
          <ContentVerticalIndent />
        </Block>
        <Block width={6} mobile={12}>
          <Image
            src={cover}
            previewSrc={previewCover}
            alt={altAndTitle}
            title={altAndTitle}
            maxWidth={isMobile ? 700 : 409}
          />
        </Block>
        <Block
          width={6}
          mobile={12}
          reorderDesktop={1}
        >
          <div className='price'>{price}</div>
          <ContentVerticalIndent />
          {linkPrice && (
            <Link
              type='secondary'
              to={linkPrice}
            >
              <Button background={priceBg}>Купить <Basket /></Button>
            </Link>
          )}
        </Block>
      </Section>
      <VerticalIndent />
      <TopicPages topicBefore={topicBefore} topicAfter={topicAfter} />
      <TopicContent content={content} />
      <VerticalIndent />
      {[].concat(topicIds || []).length > 0 && (
        <div>
          <div className={styles.headingWrapper}>
            <H3>Другие новинки</H3>
          </div>
          <Feed
            grid
            card={Card3Type2}
            columns={columns}
            interitemSpacing={spacing}
            ids={topicIds.join(',')}
          />
        </div>
      )}
      <VerticalIndent />
      <Comments  {...commProps} />
      <VerticalIndent />
    </div>
  );
}

WishlistTopic.propTypes = {
  isMobile: PropTypes.bool,
  content: modelPropTypes(topicAttributes).isRequired,
  topicBefore: PropTypes.array,
  topicAfter: PropTypes.array,
  theme: PropTypes.object,
  breakpoint: breakpointPropTypes(),
};

export default withBreakpoint(withTheme(WishlistTopic));
