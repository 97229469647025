import PropTypes from 'prop-types';

import { Desktop } from 'core/components/breakpoint';
import Drum from 'core/components/Drum';

import SectionTitle from 'site/components/SectionTitle';
import CardProduct from 'site/cards/CardProduct';

import styles from './index.styl';

export default function WishList({ content }) {
  return (
    <div className={styles.wishlistWrapper}>
      <Drum
        content={content}
        dataQa='wish-list'
        title='WISH LIST'
        card={CardProduct}
        spaceBetween={0}
        itemWidth={300}
        itemWidthMobile={286}
        headerComponent={() => (
          <div className={styles.titleWrapper}>
            <SectionTitle align='center'>WISH LIST</SectionTitle>
            <Desktop>
              <SectionTitle
                align='center'
                level={5}
                className={styles.subtitle}
              >
                Нам&nbsp;понравилось выбирать&nbsp;подарки
              </SectionTitle>
            </Desktop>
          </div>
        )}
      />
    </div>
  );
}

WishList.propTypes = {
  content: PropTypes.array,
};
