import { Fragment } from 'react';
import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import queryString from 'core/libs/query-string';

import { topicsFetcher } from 'core/fetchers/tagTopics';

import withPageHocs from 'core/components/withPageHocs';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';
import Topic from 'core/components/Topic';

import StarTopic from 'site/pages/topic/content/star';

import TopicsTaggedWithStar from 'site/components/TopicsTaggedWithStar';
import Boroda from 'site/components/Boroda';
import TopicFooter from 'site/components/TopicFooter';
import InnerSideColumn from 'site/components/InnerSideColumn';
import { VerticalIndent } from 'site/components/Indents';

import { STARS_LIMIT } from 'site/constants';

import Card5 from 'site/cards/Card5';


const include = filterRequiredParams([Card5], 'include');
const fields = filterRequiredParams([Card5], 'fields');

function Star({ starTopic, topicsDataAndMeta }) {
  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn />}>
        {!!starTopic.length && (
          <Topic
            content={starTopic[0]}
            hideAmpLink
          >
            {content => {
              return (
                <Fragment>
                  <StarTopic content={content} />
                  <TopicFooter content={content} />
                </Fragment>
              );
            }}
          </Topic>
        )}
        <TopicsTaggedWithStar
          topicsDataAndMeta={topicsDataAndMeta}
          excludedId={starTopic[0]?.id}
        />
      </ColumnLayout>
      <VerticalIndent />
      <Boroda />
    </PageIndent>
  );
}

Star.propTypes = {
  starTopic: PropTypes.array,
  topicsDataAndMeta: PropTypes.object,
};

const dataProvider = resolve({
  starTopic: props => {
    const {
      location: { search },
    } = props;

    const { preview_token: previewToken } = queryString.parse(search);

    return topicsFetcher({
      limit: 1,
      include: 'all',
      topic_type: 'star',
      preview_token: previewToken,
    })(props)
      .then(denormalizeData);
  },

  topicsDataAndMeta: props => {
    const {
      bebopApi,
      match: { params: { level_2: level2 } },
    } = props;

    return bebopApi
      .getTopics({
        include,
        fields,
        tag: level2,
        offset: 0,
        limit: STARS_LIMIT * 5,
        with_filtered_count: 1,
      })
      .then(data => ({
        topics: denormalizeData(data),
        meta: data.meta,
      }));
  },
});

export default withPageHocs(dataProvider)(Star);
