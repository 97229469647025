import React from 'react';
import PropTypes from 'prop-types';

import { nest } from 'core/libs/recompose';

import TopicContent from 'core/components/TopicContent';

import GalleryWithAds from 'site/components/GalleryWithAds';

import resolveRelationships from 'core/utils/relationships';

import {
  InRead,
  Native3,
  AdCenter,
} from '../Ads/desktop';

import {
  InPage,
  Content1,
  Content2,
  Content3,
  Content4,
  ContentSpec,
} from 'site/components/Ads/mobile';

import { NegativeMobileSideIndent } from 'site/components/Indents';

import Number from 'site/vikonts/Number';
import Opinion from 'site/vikonts/Opinion';
import ReadMore from 'site/vikonts/ReadMore';

import { coverAsGallery } from 'site/utils';

const relationships = resolveRelationships(['content'], {});

const desktopAds = [
  {
    before: 200,
    after: 1,
    showOnlyOnce: true,
    renderer: AdCenter,
  },
  {
    before: 2000,
    after: 200,
    showOnlyOnce: true,
    renderer: InRead,
  },
  {
    before: 1000,
    after: 200,
    showOnlyOnce: true,
    renderer: Native3,
  },
  {
    before: 1600,
    after: 200,
    renderer: AdCenter,
  },
  {
    before: 1000,
    after: 200,
    renderer: Native3,
  },
];

const mobileAds = [
  Content1,
  InPage,
  ContentSpec,
  Content2,
  Content3,
  Content4,
].map((renderer, index, ads) => ({
  before: 650,
  after: 100,
  showOnlyOnce: ads.length !== index + 1,
  renderer: nest(NegativeMobileSideIndent, renderer),
}));


function EarsMobileBlock({ isMobile, children }) {
  if (isMobile) return <NegativeMobileSideIndent>{children}</NegativeMobileSideIndent>;
  return children;
}

EarsMobileBlock.propTypes = {
  children: PropTypes.node,
  isMobile: PropTypes.bool,
};

export default function SiteTopicContent({ content }) {
  const {
    content: {
      widgets,
    },
  } = relationships(content);

  /**
   * Если в новости первый виджет - мини-галерея, ее ставить вместо обложки.
   * Соответственно из контента ее убираем.
   * https://jira.rambler-co.ru/browse/UCMS-2243
   */
  const contentWidgets = coverAsGallery(content)
    ? widgets.slice(1, widgets.length)
    : widgets;

  return (
    <TopicContent
      content={contentWidgets}
      blocks={{
        readMore(block, parentProps) {
          return <ReadMore {...{ block, parentProps }} />;
        },

        number(block, parentProps) {
          return  <Number number={block} parentProps={parentProps} />;
        },

        opinion(block, parentProps) {
          return <Opinion opinion={block} parentProps={parentProps} />;
        },

        photoGallery(block, parentProps) {
          return <GalleryWithAds photoGallery={block} parentProps={parentProps} />;
        },
      }}
      desktopAds={desktopAds}
      mobileAds={mobileAds}
    />
  );
}

SiteTopicContent.propTypes = {
  content: PropTypes.object,
};
