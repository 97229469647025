export default {
  0: {
    showButton: true,
    imageWidth: 460,
    imageHeight: 420,
    skewedSides: 10,
    mobile: {
      showButton: false,
      imageWidth: 439,
      imageHeight: 413,
      skewedSides: 80,
    },
  },
  1: {
    showButton: false,
    imageWidth: 404,
    imageHeight: 242,
    skewedSides: 0,
    mobile: {
      showButton: false,
      imageWidth: 345,
      imageHeight: 215,
      skewedSides: 0,
    },
  },
};
