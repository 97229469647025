export default {
  0: {
    withAltHeadline: false,
    imageMaxWidth: 248,
    imageMaxHeight: 192,
    imageVersion: 'original',
  },
  1: {
    withAltHeadline: true,
    imageMaxWidth: 248,
    imageMaxHeight: 192,
    imageVersion: 'main',
  },
  2: {
    withAltHeadline: true,
    imageMaxWidth: 292,
    imageMaxHeight: 230,
    imageVersion: 'main',
  },
  3: {
    withAltHeadline: false,
    imageMaxWidth: 248,
    imageMaxHeight: 246,
    imageVersion: 'original',
  },
  4: {
    size: 'sm',
    withAltHeadline: false,
    imageMaxWidth: 200,
    imageMaxHeight: 200,
    imageVersion: 'original',
  },
  5: {
    size: 'sm',
    withAltHeadline: false,
    imageMaxWidth: 180,
    imageMaxHeight: 180,
    imageVersion: 'original',
  },
};
