import PropTypes from 'prop-types';

import { rawAuthorsFetcher } from 'core/fetchers/authorsPage';

import resolve from 'core/resolver/resolve';

import AuthorsPage from 'core/components/AuthorsPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';
import withPageHocs from 'core/components/withPageHocs';

import SectionTitle from 'site/components/SectionTitle';
import { VerticalIndent } from 'site/components/Indents';
import Boroda from 'site/components/Boroda';
import { InnerSideColumnShort } from 'site/components/InnerSideColumn';


function Authors({ rawAuthors }) {
  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumnShort />}>
        <SectionTitle htmlTag='h1'>Авторы</SectionTitle>
        <VerticalIndent />
        <AuthorsPage rawAuthors={rawAuthors} />
      </ColumnLayout>
      <VerticalIndent />
      <Boroda />
      <VerticalIndent />
    </PageIndent>
  );
}

Authors.propTypes = {
  rawAuthors: PropTypes.object,
};

const dataProvider = resolve({
  rawAuthors: rawAuthorsFetcher(),
});

export default withPageHocs(dataProvider)(Authors);
