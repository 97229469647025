import resolveRelationships from 'core/utils/relationships';

import { withProps } from 'core/libs/recompose';

const relationships = resolveRelationships(['tags'], {});

const withContentTransform = withProps(({ content }) => {
  const { tags } = relationships(content);

  return {
    content: {
      ...content,
      attributes: {
        ...content.attributes,
        link: `/stars/${tags[0].attributes.slug}`,
      },
    },
  };
});

/**
 * HOC для для формирования ссылки типа `/stars/<tag_slug>`
 *
 * @author Aleksey Dibrivniy <a.dibrivniy@rambler-co.ru>
 */
export default function withStarLink(Component) {
  const WrappedComponent = withContentTransform(Component);

  WrappedComponent.requiredPayloadImports = ['tags']
    .concat(Component.requiredPayloadImports || [])
    .filter((item, i, list) => i === list.indexOf(item));

  return WrappedComponent;
}
