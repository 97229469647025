import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import resolve from 'core/resolver/resolve';

import Card1 from 'site/cards/Card1';
import Card3 from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';
import Card5 from 'site/cards/Card5';
import Card6 from 'site/cards/Card6';
import CardProduct from 'site/cards/CardProduct';

import horoscopeFetcher from 'site/fetchers/horoscope';


export default resolve({
  mainTopics: ({ bebopApi, consoleError }) => bebopApi
    .getTopics({
      list: 'main',
      sort: 'list',
      limit: 4,
      fields: filterRequiredParams([Card1, Card3, Card4], 'fields'),
      include: filterRequiredParams([Card1, Card3, Card4], 'include'),
      visibility: 'main_page',
    })
    .then(denormalizeData)
    .catch(consoleError('main list', [])),

  wishlist: ({ bebopApi, consoleError }) => bebopApi
    .getTopics({
      fields: filterRequiredParams([CardProduct], 'fields'),
      include: filterRequiredParams([CardProduct], 'include'),
      topic_type: 'product',
      limit: 5,
      visibility: 'main_page',
    })
    .then(denormalizeData)
    .catch(consoleError('products', [])),

  news: ({ isMobile, bebopApi, consoleError }) => bebopApi
    .getTopics({
      fields: filterRequiredParams([isMobile ? Card3 : Card4], 'fields'),
      include: filterRequiredParams([isMobile ? Card3 : Card4], 'include'),
      topic_type: 'news',
      sort: '-published_at',
      limit: 6,
      visibility: 'main_page',
    })
    .then(denormalizeData)
    .catch(consoleError('news', [])),

  videoTopics: ({ bebopApi, consoleError }) => bebopApi
    .getTopics({
      fields: filterRequiredParams([Card5], 'fields'),
      include: filterRequiredParams([Card5], 'include'),
      topic_type: 'video',
      sort: '-published_at',
      limit: 5,
      visibility: 'main_page',
    })
    .then(denormalizeData)
    .catch(consoleError('videos', [])),

  galleryTopics: ({ bebopApi, consoleError }) => bebopApi
    .getTopics({
      fields: filterRequiredParams([Card5], 'fields'),
      include: filterRequiredParams([Card5], 'include'),
      list: 'photo',
      sort: 'list',
      limit: 5,
      visibility: 'main_page',
    })
    .then(denormalizeData)
    .catch(consoleError('photo list', [])),

  horoscopeTopics: horoscopeFetcher({
    include: 'horoscope',
    visibility: 'main_page',
  }),

  trends: ({ isMobile, bebopApi, consoleError }) => bebopApi
    .getTopics({
      fields: filterRequiredParams([isMobile ? Card4 : Card6], 'fields'),
      include: filterRequiredParams([isMobile ? Card4 : Card6], 'include'),
      list: 'trends',
      sort: 'list',
      limit: 8,
      visibility: 'main_page',
    })
    .then(denormalizeData)
    .catch(consoleError('trends list', [])),

  popular: ({ isMobile, bebopApi, consoleError }) => bebopApi
    .getTopics({
      fields: filterRequiredParams([isMobile ? Card3 : Card5], 'fields'),
      include: filterRequiredParams([isMobile ? Card3 : Card5], 'include'),
      sort: '-views_count',
      limit: 12,
      visibility: 'main_page',
    })
    .then(denormalizeData)
    .catch(consoleError('popular', [])),

  firstColumnTopics: ({ isMobile, bebopApi, consoleError }) => bebopApi
    .getTopics({
      list: 'column_1',
      sort: 'list',
      limit: 4,
      fields: filterRequiredParams([isMobile ? Card3 : Card4], 'fields'),
      include: filterRequiredParams([isMobile ? Card3 : Card4], 'include'),
      visibility: 'main_page',
    })
    .then(denormalizeData)
    .catch(consoleError('column_1 list', [])),

  secondColumnTopics: ({ isMobile, bebopApi, consoleError }) => bebopApi
    .getTopics({
      list: 'column_2',
      sort: 'list',
      limit: 1,
      fields: filterRequiredParams([isMobile ? Card3 : Card4], 'fields'),
      include: filterRequiredParams([isMobile ? Card3 : Card4], 'include'),
      visibility: 'main_page',
    })
    .then(denormalizeData)
    .catch(consoleError('column_2 list', [])),
});
