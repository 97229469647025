import PropTypes from 'prop-types';

import readMore from 'core/components/TopicContent/blocks/readMore';

import modelPropTypes, { readMoreAttributes } from 'core/utils/prop-types/model';

import { Card3Type3 } from 'site/cards/Card3';
import { Card4Type4, Card4Type5 } from 'site/cards/Card4';


export default function ReadMore(props) {
  const {
    block,
    parentProps: {
      isMobile,
    },
  } = props;

  const contentArr = block && block.attributes ? block.attributes.topic_ids : [];

  if (!contentArr.length) {
    return null;
  }

  return (
    <div className='readMore'>
      {readMore(
        block,
        {
          title: isMobile ? 'Читайте также' : 'Читайте на тему:',
          singleCard: Card4Type4,
          feedCard: Card3Type3,
          drumCard: isMobile ? Card4Type4 : Card4Type5,
        }
      )}
    </div>
  );
}

ReadMore.propTypes = {
  block: modelPropTypes(readMoreAttributes),
  parentProps: PropTypes.object,
};
