import React, { Fragment } from 'react';
import PropTypes from 'prop-types';


const GlobalStyles = (props) => {
  const {
    theme: {
      animations: {
        hover: hoverAnimation,
      },
      colors: colorsAtoms,
      fonts: fontsAtoms,
      controls: {
        link: {
          secondary: secondaryLinkAtoms,
        },
      },
    },
  } = props;

  return (
    <Fragment>
      <style global jsx>{`
        .adf-wrapper
          .ad_native_block
            text-align left

            a
              padding-top 20px
              display block
              cursor pointer

              &:hover .ad_native_img:before
                opacity .2

          .ad_native_img
            float right
            font-size 0
            margin-left 20px
            position relative

            &:before
              top 0
              left 0
              right 0
              bottom 0
              content ''
              position absolute
              opacity 0

            img
              position absolute
              top 50%
              left 50%
              transform translate(-50%, -50%)
              max-width 100%
              max-height 100%

          .ad_native_title_wrapper
            overflow hidden

          .ad_native_title
            font-size 20px
            line-height 1.15   

          .ad_native_desc
            overflow hidden

          .ad_native_sponsored_clone
            font-size 10px
            text-transform uppercase
            margin-bottom 5px
            display block

          .ad_img_text
          .ad_native_desc_2
          .ad_native_time
          .ad_native_date
          .ad_native_sponsored
          .ad_native_cta_text
            display none
      `}</style>

      <style global jsx>{`
        .adf-wrapper
          .ad_native_block
            a
              font-family ${fontsAtoms.text}
              color ${secondaryLinkAtoms.idle.color}
              text-decoration ${secondaryLinkAtoms.idle.decoration}
              transition color ${hoverAnimation}

              &:hover
                color ${secondaryLinkAtoms.hover.color}
                text-decoration ${secondaryLinkAtoms.hover.decoration}

              &:active
                color ${secondaryLinkAtoms.active.color}
                text-decoration ${secondaryLinkAtoms.active.decoration}

              &:hover
                .ad_native_title
                .ad_native_desc
                  color ${colorsAtoms.active1}

              &:active
                .ad_native_title
                .ad_native_desc
                  color ${colorsAtoms.primary}

          .ad_native_img
            &:before
              background-color ${colorsAtoms.active1}
              transition opacity ${hoverAnimation}

          .ad_native_title
          .ad_native_desc
            color ${colorsAtoms.primary}
            transition color ${hoverAnimation}

          .ad_native_sponsored_clone
            color ${colorsAtoms.hint}
      `}</style>
    </Fragment>
  );
};

GlobalStyles.propTypes = {
  theme: PropTypes.object,
};

export default GlobalStyles;
