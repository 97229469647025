import PropTypes from 'prop-types';

import bindProps from 'core/components/bindProps';
import AuthorPage from 'core/components/AuthorPage';

import resolve from 'core/resolver/resolve';

import {
  authorFetcher,
  topicsFetcher,
} from 'core/fetchers/authorPage';

import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';
import withPageHocs from 'core/components/withPageHocs';
import { DESKTOP, MOBILE } from 'core/components/breakpoint/values';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import Card5 from 'site/cards/Card5';
import { Card3Type1 } from 'site/cards/Card3';

import SectionTitle from 'site/components/SectionTitle';
import { InnerSideColumnShort } from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';
import { VerticalIndent } from 'site/components/Indents';


const breakpointCards = {
  [DESKTOP]: bindProps({ imageMaxWidth: 426 })(Card5),
  [MOBILE]: Card3Type1,
};


function Author(props) {
  const {
    breakpoint,
    author,
    topics,
  } = props;

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumnShort />}>
        <AuthorPage
          grid
          author={author}
          topics={topics}
          columns={2}
          card={breakpointCards[breakpoint]}
          interitemSpacing={20}
        />
      </ColumnLayout>
      <VerticalIndent />
      <Boroda />
      <VerticalIndent />
    </PageIndent>
  );
}

const dataProvider = resolve({
  author: authorFetcher(),
  topics: props => topicsFetcher({
    include: 'image,rubric',
    limit: 10,
    topicsTitleComponent: bindProps({ level: 4 })(SectionTitle),
    allAuthorsLinkComponent: bindProps({ level: 6 })(SectionTitle),
  })(props),
});

Author.propTypes = {
  topics: PropTypes.object,
  author: PropTypes.object,

  /** @ignore */
  breakpoint: breakpointPropTypes(),
};

export default withBreakpoint(withPageHocs(dataProvider)(Author));
