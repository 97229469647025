import PropTypes from 'prop-types';

import modelPropTypes, {
  opinionAttributes,
} from 'core/utils/prop-types/model';

import Image from 'core/components/Image';
import SectionTitle from 'site/components/SectionTitle';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import styles from './index.styl';

/**
 * @author Evgeny Shiryaev <e.shiryaev@rambler-co.ru>
 */

export default function Opinion(props) {
  const {
    opinion: {
      attributes: {
        body,
        author,
      },
    },
    parentProps: {
      theme,
    },
  } = props;

  const attr = author ? author.attributes : {};
  const name = attr.name || '';
  const jobTitle = attr.job_title || '';
  const cover = attr.avatar ? attr.avatar.attributes.versions.original : '';

  return (
    <div className={styles.vikont}>
      <style jsx>{`
        .${styles.text}
          font ${theme.texts.quote.font}
          color ${theme.texts.quote.color}

          &:before
          &:after
            color ${theme.colors.active2}

        .${styles.sign}
          font ${theme.texts.caption2.font}
          color ${theme.colors.hint}
      `}</style>

      {cover && (
        <div className={styles.userImg}>
          <Image
            src={cover}
            width={140}
            height={140}
          />
        </div>
      )}

      <div className={styles.content}>
        {body && (
          <div className={styles.text}>
            <MarkdownWrapper interitemSpacing={20}>
              {body}
            </MarkdownWrapper>
          </div>
        )}
        {name && (
          <SectionTitle level={3} align='center'>
            <MarkdownWrapper>
              {name}
            </MarkdownWrapper>
          </SectionTitle>
        )}
        {jobTitle && (
          <div className={styles.sign}>
            <MarkdownWrapper>
              {jobTitle}
            </MarkdownWrapper>
          </div>
        )}
      </div>
    </div>
  );
}

Opinion.propTypes = {
  opinion: modelPropTypes(opinionAttributes).isRequired,
  parentProps: PropTypes.object,
};
