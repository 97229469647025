import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import CommentsPreview from 'core/components/CommentsPreview';

function TopicComments({ link, xid }) {
  return (
    <Fragment>
      <CommentsPreview xid={xid} commentsUrl={`${link}/comments`} />
    </Fragment>
  );
}

TopicComments.propTypes = {
  xid: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default TopicComments;
