import Avatar from 'core/components/Avatar';
import Link from 'core/components/Link';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';
import { dateFormat } from 'core/utils/dates';
import { parenthesesPattern } from 'site/constants';

import styles from './index.styl';

const DATES_FORMAT = 'd MMMM';

const requiredPayloadImports = [
  'image',
  'person_meta',
];

const requiredPayloadFields = [
  'headline',
  'link',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

function CardSameZodiac(props) {
  const {
    content,
  } = props;

  if (!content) return null;

  const {
    headline,
    link,
  } = content.attributes;

  const {
    image: {
      versions: {
        star: cover,
      },
    },
    person_meta: {
      birth_date: birthDateString,
    },
  } = relationships(content);

  const birthDate = dateFormat(new Date(birthDateString), DATES_FORMAT);
  const starHeadline = headline.replace(parenthesesPattern, '');

  return (
    <Link
      to={link}
      type='secondary'
      className={styles.link}
    >
      <Avatar
        isCircular
        src={cover}
        size={80}
      />
      <div className={styles.info}>
        <div className={styles.birthDate}>
          {birthDate}
        </div>
        <div className={styles.headline}>
          {starHeadline}
        </div>
      </div>
    </Link>
  );
}

CardSameZodiac.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
};

CardSameZodiac.requiredPayloadImports = requiredPayloadImports;
CardSameZodiac.requiredPayloadFields = requiredPayloadFields;

export default CardSameZodiac;
