import React from 'react';
import PropTypes from 'prop-types';

import { denormalizeData } from 'core/utils/api';

import cache from 'core/resolver/cache';
import resolve from 'core/resolver/resolve';

import withStatusHandlers from 'core/components/BaseRoutes/withStatusHandlers';

import { dateFormat } from 'core/utils/dates';


export const StarsBirthdayContext = React.createContext({
  starsBirthday: [],
});

function StarsBirthdayProvider({ children, starsBirthday }) {
  return (
    <StarsBirthdayContext.Provider value={{ starsBirthday }}>
      {children}
    </StarsBirthdayContext.Provider>
  );
}

StarsBirthdayProvider.propTypes = {
  starsBirthday: PropTypes.array,
};

const dataProvider = resolve({
  // eslint-disable-next-line no-unused-vars
  starsBirthday: ({ starsBirthday, bebopApi, consoleError }) => {
    return starsBirthday || bebopApi
      .getTopics({
        limit: 10,
        include: 'image,person_meta',
        person_birth_date: dateFormat(new Date(), 'yyyy-MM-dd'),
      })
      .then(denormalizeData)
      .catch(consoleError('stars birthdays', []));
  },
});

/**
 * TODO:
 * PERF:
 * В этот контекст обёрнут весь сайт, что приводит к двум
 * последовательным запросам: один для этого контекста и один
 * для получения данных страницы. Надо придумать, как это оптимизировать
 */
export default cache({
  cacheKey: 'starsBirthday',
  maxSize: 5,
  maxAge: 10 * 60 * 1000,
})(withStatusHandlers(dataProvider(StarsBirthdayProvider)));
