import React from 'react';
import PropTypes from 'prop-types';

import bindProps from 'core/components/bindProps';
import Raker from 'core/components/Raker';
import { Block, Section } from 'core/components/Grid';
import ContentBackground from 'core/components/ContentBackground';

import {
  SideIndent,
  BLOCK_VERTICAL,
  BLOCK_HORIZONTAL,
} from '../Indents';

import SkewedContainer from 'site/components/SkewedContainer';

import Card2 from 'site/cards/Card2';
import { Card5Type1 } from 'site/cards/Card5';

import styles from './index.styl';


const RakerCard = bindProps({ imageMaxWidth: 252, imageMaxHeight: 170 })(Card5Type1);

export default function SkewedRaker(props) {
  const {
    color,
    ...otherProps
  } = props;

  return (
    <SkewedContainer color={color} overflow>
      {({ height }) => (
        <Section>
          <style jsx>{`
            .rakerOuterIndent
              margin-top ${-height + 'px'}
              margin-bottom ${-height + 'px'}

            .rakerInnerIndent
              padding-right ${BLOCK_HORIZONTAL}px
              padding-top ${height - BLOCK_VERTICAL * 2 + 'px'}
              padding-bottom ${height + 'px'}
          `}</style>

          <SideIndent tablet={0} desktop={0} />
          <Block>
            <div className='rakerOuterIndent'>
              <ContentBackground>
                <div className='rakerInnerIndent'>
                  <Raker
                    {...otherProps}
                    heroCard={heroProps => (
                      <div
                        className={styles.skewedHeroCard}
                        style={{
                          marginTop: -height / 2.3,
                          marginBottom: -height * 2,
                        }}
                      >
                        <Card2 {...heroProps} />
                      </div>
                    )}
                    card={RakerCard}
                    sidebarGridSize='564px'
                    interitemSpacing={30}
                  />
                </div>
              </ContentBackground>
            </div>
          </Block>
          <SideIndent tablet={0} desktop={0} />
        </Section>
      )}
    </SkewedContainer>
  );
}

SkewedRaker.propTypes = {
  color: PropTypes.string,
};

SkewedRaker.defaultProps = {
  sidebarGridSize: '300px',
  interitemSpacing: 30,
};
