import PropTypes from 'prop-types';
import cx from 'classnames';

import { withRouter } from 'core/libs/router';

import EmptyWrapper from 'core/components/EmptyWrapper';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import Divider from 'site/components/Divider';
import InnerSideColumn from 'site/components/InnerSideColumn';

import styles from './index.styl';

const tagsHeight = {
  'moda': 116,
  'krasota': 158,
  'stil-zhizni': 158,
  'otnosheniya': 116,
  'zvezdy': 116,
  'default': 74,
};

function Skeleton({ location }) {
  let rubric = location.pathname.slice(1);
  if (rubric === 'otnosheniya/lyubov-i-seks') {
    rubric = 'otnosheniya';
  }

  const rubricsWithoutTitle =
    (rubric === 'photo') ||
    (rubric === 'news') ||
    (rubric === 'horoscope');

  const tagHeight = tagsHeight[rubric] || tagsHeight.default;

  return (
    <PageIndent>
      <style jsx>{`
        .${styles.tags}
          &._${rubric}
            :global(.desktop) &
              height ${tagHeight}px
      `}</style>
      <ColumnLayout rightColumn={<InnerSideColumn />}>
        {!rubricsWithoutTitle &&
          <EmptyWrapper>
            <div className={cx(styles.title, 'lb-skeleton')} />
            <Divider bottom={43} />
            <div className={cx(styles.tags, `_${rubric}`, 'lb-skeleton')} />
          </EmptyWrapper>
        }
        <div className={styles.topics}>
          <div className={cx(styles.topic, 'lb-skeleton')} />
          <div className={cx(styles.topic, 'lb-skeleton')} />
        </div>
      </ColumnLayout>
    </PageIndent>
  );
}

Skeleton.propTypes = {
  location: PropTypes.object,
};

export default withRouter(Skeleton);
