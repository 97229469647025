import React from 'react';
import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';

import Recommender from 'core/components/Recommender';

import SectionTitle from 'site/components/SectionTitle';
import bindProps from 'core/components/bindProps';

import Card5 from 'site/cards/Card5';
import { Card3Type1 } from 'site/cards/Card3';


const RecommenderCard = bindProps({ imageMaxWidth: 270 })(Card5);

function SiteRecommender({ title, headerComponent, isMobile, isDesktop }) {
  return (
    <Recommender
      blockId={isMobile ? '17ea4c9a069a431b8cd205f81bfddaa4' : '8b438472030246ef859a15f47d8f3d09'}
      title={title}
      card={isDesktop ? RecommenderCard : Card3Type1}
      headerComponent={headerComponent}
      interitemSpacing={30}
      columns={4}
      grid
      limit={isMobile ? 3 : 8}
    />
  );
}

SiteRecommender.defaultProps = {
  title: 'Читайте также',
  headerComponent: SectionTitle,
};

SiteRecommender.propTypes = {
  title: PropTypes.string,
  headerComponent: PropTypes.func,
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(SiteRecommender);
