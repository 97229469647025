import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withBreakpoint, Desktop } from 'core/components/breakpoint';
import { Indent } from 'core/components/Wrappers';

import TopicToplineSkeleton from 'core/components/TopicTopline/Skeleton';
import SocializatorSkeleton from 'core/components/Socializator/Skeleton';
import TopicHeaderTextsSkeleton from 'core/components/TopicHeaderTexts/Skeleton';

import styles from './index.styl';

function Story({ isMobile }) {
  return (
    <Fragment>
      <TopicToplineSkeleton height={isMobile ? 25 : 33} />
      <Indent top={20} />
      <TopicHeaderTextsSkeleton
        titleHeight={isMobile ? 103 : 161}
        leadHeight={isMobile ? 72 : 68}
      />
      <Indent top={20} />
      <Desktop>
        <div className={styles.socializator}>
          <SocializatorSkeleton />
        </div>
      </Desktop>
      <Indent top={20} />
      <div className={cx('lb-skeleton', styles.cover)} />
    </Fragment>
  );
}

Story.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Story);
