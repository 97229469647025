/**
 * ID требуется для компонента Feed.
 */
const content = [
  {
    id: 'spec090',
    cover: require('./images/spec090.jpg'),
    headline: 'Успей на главные события этого года',
    announce: 'Где взять время, чтобы всегда выглядеть на 100%? Тебе пригодятся лайфхаки, благодаря которым ты с одинаковой легкостью соберешься и на Пикник Афиши в Москве, и на Оперный фестиваль в Мюнхене.',
    link: 'http://taftevents.wmj.ru/',
    height: 315,
    width: 600,
  },
  {
    id: 'spec100',
    cover: require('./images/spec100.jpg'),
    headline: 'Твои волосы — твои правила!',
    announce: 'Посмотри на фото героинь нашего проекта и попробуй угадать факты из биографий девушек!',
    link: 'http://dove.wmj.ru/',
    height: 628,
    width: 1200,
  },
  {
    id: 'spec110',
    cover: require('./images/spec110.jpg'),
    headline: 'Что иностранные мужчины думают о русских девушках',
    announce: 'Иностранцы особенно подмечают русскую красоту. Мы решили спросить мужчин, живущих за пределами России, что же их особенно привлекает в нас. Узнай, что нужно делать, чтобы покорить сердце любого мужчины?',
    link: 'http://springmiracle.wmj.ru/',
    height: 315,
    width: 600,
  },
  {
    id: 'spec120',
    cover: require('./images/spec120.jpg'),
    headline: 'Простые правила красивого отдыха',
    announce: 'Что нужно делать, чтобы отпуск не превратился в настоящее испытание для ваших волос.',
    link: 'http://jf.wmj.ru/',
    height: 1236,
    width: 2380,
  },
  {
    id: 'spec140',
    cover: require('./images/spec140.jpg'),
    headline: 'SPA для ваших рук',
    announce: 'Уход за руками всегда был важен для женщин, даже тысячелетия назад. Узнай все секреты красавиц древних времен!',
    link: 'http://colortherapy.wmj.ru/',
    height: 630,
    width: 1200,
  },
  {
    id: 'spec150',
    cover: require('./images/spec150.jpg'),
    headline: 'Дыши полной жизнью',
    announce: 'Жизнь — это путь, а не пункт назначения. Как весной и летом чувствовать себя красивой, получать хорошие эмоции и яркие впечатлении, невзирая на сезонную аллергию?',
    link: 'http://flixonase.wmj.ru/?page=main#main',
    height: 630,
    width: 1200,
  },
  {
    id: 'spec160',
    cover: require('./images/spec160.jpg'),
    headline: 'Мир без границ',
    announce: 'Читай в нашем проекте о том, как стоит познакомиться с местными красавцами во время путешествий в самые популярные страны мира и произвести на них впечатление!',
    link: 'http://zalain.wmj.ru/',
    height: 628,
    width: 1200,
  },
];

/**
 * Преобразуем данные в swagger схему, необходимую для карточки
 * requiredPayloadImports = ['image', 'rubric'] обязательны для заполнения
 */
export default content.map(item => {
  return {
    id: item.id,
    type: 'topic',
    attributes: {
      topic_type: 'article',
      headline: item.headline,
      published_at: '2017-11-20T12:57:00.000Z',
      link: item.link,
      alternative_headline: item.announce,
    },
    relationships: {
      rubric: {
        data: {
          id: `${item}-rubric`,
          type: 'rubric',
          attributes: {
            slug: 'slug',
            title: 'title',
            enabled: true,
            root_slug: 'root_slug',
            root_title: 'root_title',
          },
        },
      },
      image: {
        data: {
          id: `${item}-cover`,
          type: 'image',
          attributes: {
            versions: {
              original: {
                rel_url: item.cover,
                width: item.width,
                height: item.height,
              },
            },
          },
        },
      },
    },
  };
});
