import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import { withBreakpoint } from 'core/components/breakpoint';
import bindProps from 'core/components/bindProps';

import EmptyWrapper from 'core/components/EmptyWrapper';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';
import Bouesque from 'core/components/Bouesque';

import Story from './Story';
import Horoscope from './Horoscope';
import WishList from './WishList';


const isHoroscope = pathname => /^\/goroskop/.test(pathname);
const isWishlist = pathname => /^\/wishlist/.test(pathname);

function getTopicType(pathname) {
  if (isHoroscope(pathname)) {
    return 'horoscope';
  } else if (isWishlist(pathname)) {
    return 'wishlist';
  }

  return 'default';
}

const loadersMap = {
  horoscope: Horoscope,
  wishlist: WishList,
  default: Story,
};

function Skeleton({ isMobile, location }) {
  const ContentWrapper = isMobile
    ? EmptyWrapper
    : bindProps({ rightColumn: <Bouesque />, sideColumnIndent: '75px' })(ColumnLayout);

  const topicType = getTopicType(location.pathname);
  const Content = loadersMap[topicType];

  return (
    <PageIndent>
      <ContentWrapper>
        <Content />
      </ContentWrapper>
    </PageIndent>
  );
}

Skeleton.propTypes = {
  isMobile: PropTypes.bool,
  location: PropTypes.object,
};

export default withRouter(withBreakpoint(Skeleton));
