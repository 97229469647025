export default {
  sm: {
    headline: {
      fontSize: '16px',
      lineHeight: 1.3,
    },
    altHeadline: {
      fontSize: '14px',
      lineHeight: 1.3,
    },
  },
  md: {
    headline: {
      fontSize: '18px',
      lineHeight: 1.2,
    },
    altHeadline: {
      fontSize: '16px',
      lineHeight: 1.3,
    },
  },
};
